<template>
  <div v-if="wizardImpactType === 1 && element.type === 'plant' && showSinkEditor && allowPlantFormula">
    <v-dialog
      v-model="showCo2FormulaDialog"
      width="600"
    >
      <template #activator="{ on }">
        <v-btn
          color="shamrock white--text"
          elevation="0"
          v-on="on"
        >
          Edit plant formula
        </v-btn>
      </template>
      <SinkEditor
        :visible="showCo2FormulaDialog"
        :existing-formula="existingFormula"
        @save="e => setSinkFormula(e, true)"
        @close="e => setSinkFormula()"
      />
    </v-dialog>
    <v-btn
      class="ml-2"
      color="primary white--text"
      text
      elevation="0"
      style="text-decoration: underline;"
      @click="useSinkEditor = false"
    >
      Use general formula instead
    </v-btn>
  </div>
  <div
    v-else
    class="mt-1"
  >
    <v-btn
      v-if="wizardImpactType === 1 && element.type === 'plant' && allowPlantFormula"
      class="ml-2"
      color="primary white--text"
      text
      elevation="0"
      style="text-decoration: underline;"
      @click="useSinkEditor = true"
    >
      Use plant formula instead
    </v-btn>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Variables</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="variables d-flex align-center mb-2 flex-wrap">
            <v-chip
              outlined
              :input-value="false"
              class="mr-1 mb-1"
              small
            >
              <strong class="mr-1">$qty</strong> - quantity ({{ element.co2MeasuredPer }})
            </v-chip>
            <v-chip
              v-if="wizardImpactType === 1"
              outlined
              :input-value="false"
              class="mr-1 mb-1"
              small
            >
              <strong class="mr-1">$yrs</strong> - number of years since construction (starts at 1)
            </v-chip>
            <v-chip
              v-if="impactType === 0 && element.type === 'material'"
              outlined
              :input-value="false"
              class="mr-1 mb-1"
              small
            >
              <strong class="mr-1">$replacements</strong>
            </v-chip>
            <v-chip
              v-if="element.type === 'plant'"
              outlined
              :input-value="false"
              class="mr-1 mb-1"
              small
            >
              <strong class="mr-1">$percentOfCover</strong>
            </v-chip>

            <v-chip
              v-for="item in [...variables.map((x) => x.name), ...project_variables]"
              :key="item"
              outlined
              :input-value="false"
              class="mr-1 mb-1"
              small
            >
              <strong class="mr-1">{{ item }}</strong>
            </v-chip>
          </div>
          <strong class="mr-1 d-block mb-1">Project Constants:</strong>
          <div class="variables d-flex align-center mb-2 flex-wrap">
            <v-chip
              v-for="item in projectConstants"
              :key="item.name"
              outlined
              :input-value="false"
              class="mr-1 mb-1"
              small
            >
              <strong class="mr-1">{{ item.name }}</strong>
            </v-chip>
          </div>
          <div v-if="element.type === 'hydrozone'">
            <strong class="mr-1 d-block mb-1">Hydrozone Constants:</strong>
            <v-chip
              v-for="item in eto_variables"
              :key="item"
              outlined
              :input-value="false"
              class="mr-1 mb-1"
              small
            >
              <strong class="mr-1">{{ item }}</strong>
            </v-chip>
          </div>
          <div v-if="hasEcosystem && ecosystemHasField(element)">
            <strong class="mr-1 d-block mb-1">Ecosystem Variables:</strong>
            <div class="variables d-flex align-center mb-2 flex-wrap">
              <v-chip
                v-for="item in ecosystem_variables"
                :key="item.name"
                outlined
                :input-value="false"
                class="mr-1 mb-1"
                small
              >
                <strong class="mr-1">${{ item.prefix }}_{{ item.name }}</strong>
              </v-chip>
            </div>
          </div>
          <div v-if="hasIrrigationTypeOptions && irrigationTypeHasField(element)">
            <strong class="mr-1 d-block mb-1">Irrigation Type Variables:</strong>
            <div class="variables d-flex align-center mb-2 flex-wrap">
              <v-chip
                v-for="item in irrigationTypeFields"
                :key="item.name"
                outlined
                :input-value="false"
                class="mr-1 mb-1"
                small
              >
                <strong class="mr-1">${{ item.prefix }}_{{ item.name }}</strong>
              </v-chip>
            </div>
          </div>
          <div v-if="(hasMaterialOptions || hasMaterialCategories) && materialHasField(element)">
            <strong class="mr-1 d-block mb-1">Material Variables:</strong>
            <div class="variables d-flex align-center mb-2 flex-wrap">
              <v-chip
                v-for="item in material_variables"
                :key="item"
                outlined
                :input-value="false"
                class="mr-1 mb-1"
                small
              >
                <strong class="mr-1">{{ item }}</strong>
              </v-chip>
            </div>
          </div>
          <div v-if="hasNurseryOptions && nurseryHasField(element)">
            <strong class="mr-1 d-block mb-1">Nursery Variables:</strong>
            <div class="variables d-flex align-center mb-2 flex-wrap">
              <v-chip
                v-for="item in nursery_variables"
                :key="item.name"
                outlined
                :input-value="false"
                class="mr-1 mb-1"
                small
              >
                <strong class="mr-1">${{ item.prefix }}_{{ item.name }}</strong>
              </v-chip>
            </div>
          </div>
          <div v-if="hasPlantingTypeOptions && plantingTypeHasField(element)">
            <strong class="mr-1 d-block mb-1">Planting Type Variables:</strong>
            <div class="variables d-flex align-center mb-2 flex-wrap">
              <v-chip
                v-for="item in plantingTypeFields"
                :key="item.name"
                outlined
                :input-value="false"
                class="mr-1 mb-1"
                small
              >
                <strong class="mr-1">${{ item.prefix }}_{{ item.name }}</strong>
              </v-chip>
            </div>
          </div>
          <div v-if="hasDefaultTransportationOption && hasDefaultTransportationDistanceOption">
            <strong class="mr-1 d-block mb-1">Transportation Variables:</strong>
            <div class="variables d-flex align-center mb-2 flex-wrap">
              <v-chip
                v-for="item in transportation_variables"
                :key="item"
                outlined
                :input-value="false"
                class="mr-1 mb-1"
                small
              >
                <strong class="mr-1">{{ item }}</strong>
              </v-chip>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-textarea
      ref="formula-text-field"
      text
      class="mt-3 pt-0 mb-3"
      style="width: 100%;"
      :value="co2Formula"
      :error-messages="formulaError"
      rows="5"
      placeholder="Enter a formula eg. $qty * 5.38"
      :prefix="units + ' = '"
      :hint="`Enter the formula for ${units} from a given ${wizardImpactType === 1 ? 'year and ' : ''} quantity in ${element.co2MeasuredPer}`"
      @change="handleFormulaInput($event)"
      @input="handleFormulaInput($event)"
    />
    <FormulaHelpInfo
      :variables="{
        'Key variables': {
          $qty: `Quantity (${ element.co2MeasuredPer })`,
          $yrs: 'Number of years since construction (starts at 1)',
          $replacements: 'Number of times material replaced over lifetime (materials only)',
          $percentOfCover: 'Perennials: Percent of Cover'
        },
        'Project Constants' : Object.fromEntries(projectConstants.map(({name, prettyName}) => [name, prettyName])),
        'Project variables': Object.fromEntries([
          ...ecosystem_variables.map((field) => ({
            prettyName: field.label,
            name: `${field.prefix}_${field.name}`
          })),
          ...nursery_variables.map((field) => ({
            prettyName: field.label,
            name: `${field.prefix}_${field.name}`
          })),
          ...irrigationTypeFields.map((field) => ({
            prettyName: field.label,
            name: `${field.prefix}_${field.name}`
          })),
          ...plantingTypeFields.map((field) => ({
            prettyName: field.label,
            name: `${field.prefix}_${field.name}`
          })),
          { prettyName: 'Annual ETo value (mm)', name: 'ETo' },
          { prettyName: 'Material Low Carbon Factor', name: 'm_low_carbon_factor' },
          { prettyName: 'Material Medium Carbon Factor', name: 'm_median_carbon_factor' },
          { prettyName: 'Material High Carbon Factor', name: 'm_high_carbon_factor' },
          { prettyName: 'Material Density', name: 'm_density' },
          { prettyName: 'Material Mass', name: 'm_mass' },
          { prettyName: 'Material Carbon Stored', name: 'm_carbon_stored' },
          { prettyName: 'Material Replacement Cycle', name: 'm_replacement_cycle' },
          { prettyName: 'Transportation Distance', name: 't_distance' },
          { prettyName: 'Transportation Carbon Factor', name: 't_carbon_factor' },
          { prettyName: 'Planted area of project', name: 'planted_area' },
          { prettyName: 'Total area of project', name: 'total_area' },
          { prettyName: 'Shrub area of project', name: 'shrub_area' },
          { prettyName: 'Lawn area of project', name: 'lawn_area' }
        ].map((x) => [`$${x.name}`, x.prettyName || x.description]))
      }"
    />
  </div>
</template>

<script>
import SinkEditor from './SinkEditor'
import FormulaHelpInfo from '@/components/atoms/FormulaHelpInfo'
import { elementTypeHasField as materialHasField } from '../../../materials/constants'
import { ecosystemFields, elementTypeHasField as ecosystemHasField } from '../../../ecosystems/constants'
import { nurseryFields, elementTypeHasField as nurseryHasField } from '../../../nurseries/constants'

import { irrigationTypeFields, elementTypeHasField as irrigationTypeHasField } from '../../../irrigationTypes/constants'
import { plantingTypeFields, elementTypeHasField as plantingTypeHasField } from '../../../plantingTypes/constants'

import { createNamespacedHelpers, mapGetters as mapGlobalGetters, mapActions as mapGlobalActions } from 'vuex'
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('elements')

export default {
  name: 'ImpactValues',
  components: {
    SinkEditor,
    FormulaHelpInfo
  },
  props: {
    overrideFormulaKey: {
      type: String,
      default: undefined
    },
    overrideImpactType: {
      type: Number,
      default: undefined
    },
    allowPlantFormula: {
      type: Boolean,
      default: true
    },
    requestedFormula: {
      type: String,
      default: null
    },
    units: {
      type: String,
      default: 'kgCO₂e'
    }
  },
  data () {
    const transportation_variables = ['$t_distance', '$t_carbon_factor']
    const eto_variables = ['$ETo']
    const material_variables = ['$m_low_carbon_factor', '$m_median_carbon_factor', '$m_high_carbon_factor', '$m_density', '$m_mass',
      '$m_carbon_stored', '$m_replacement_cycle']
    const ecosystem_variables = [...ecosystemFields, {
      name: 'year',
      label: 'Ecosystem Year',
      prefix: 'e'
    }]
    const nursery_variables = nurseryFields
    const project_variables = ['$planted_area', '$total_trees', '$lawn_area', '$shrub_area']

    return {
      evaluateTimeout: null,
      showCo2FormulaDialog: false,
      formulaType: 0,
      impactAlert: null,
      co2PerUnitRaw: 0,
      // replacementsRaw: 0,
      formulaError: null,
      variables: [],
      eto_variables,
      transportation_variables,
      material_variables,
      ecosystem_variables,
      nursery_variables,
      project_variables,

      irrigationTypeFields,
      plantingTypeFields,

      attributes: [...project_variables],
      useSinkEditor: null,
      internalImpactType: null
    }
  },
  computed: {
    ...mapGlobalGetters(['unitMappings', 'constants']),
    ...mapGetters(['systemOfMeasurement', 'element', 'impactType']),
    impactValueLabel () {
      return this.internalImpactType === 1 ? 'Annual Impact' : 'Impact at source'
    },
    ecosystem () {
      return this?.$store.state.elements.element.ecosystem
    },
    hasEcosystem () {
      return Boolean(this.ecosystem)
    },
    hasIrrigationTypeOptions () {
      return (this?.$store.state.elements.element.irrigationTypes || []).length > 0
    },
    hasMaterialOptions () {
      return (this?.$store.state.elements.element.materials || []).length > 0
    },
    hasMaterialCategories () {
      return (this?.$store.state.elements.element.materialCategories || []).length > 0
    },
    hasNurseryOptions () {
      return (this?.$store.state.elements.element.nurseries || []).length > 0
    },
    hasPlantingTypeOptions () {
      return (this?.$store.state.elements.element.plantingTypes || []).length > 0
    },
    projectConstants () {
      const constants = Object.entries(this.constants).map(([key, value]) => ({ name: '$' + key, prettyName: key }))
      return constants
    },
    hasDefaultTransportationOption () {
      return (this?.$store.state.elements.element.defaultTransportationOption || []).length > 0
    },
    hasDefaultTransportationDistanceOption () {
      return (this?.$store.state.elements.element.defaultTransportationDistanceOption || []).length > 0
    },
    showReplacements: {
      get () {
        return this.$store.state.elements.element.showReplacements
      },
      set (val) {
        this.setShowReplacements(val)
      }
    },
    replacements: {
      get () {
        return this.$store.state.elements.element.replacements
      },
      set (val) {
        // this.replacementsRaw = val
        this.setReplacements(+val)
        this.setParsedElementFormula({
          co2PerUnit: this.co2PerUnitRaw,
          formulaType: 0
        })
      }
    },
    percentOfCover: {
      get () {
        return this.$store.state.elements.element.percentOfCover
      },
      set (val) {
        // this.replacementsRaw = val
        this.setPercentOfCover(+val)
      }
    },
    co2PerUnit: {
      get () {
        return this.co2PerUnitRaw
      },
      set (val) {
        this.co2PerUnitRaw = val
        this.setParsedElementFormula({
          co2PerUnit: val,
          formulaType: 0
        })
      }
    },
    isFormulaMode: {
      get () {
        return this.$store.state.elements.isFormulaMode
      },
      set (val) {
        this.setFormulaMode(val)
      }
    },
    co2Formula: {
      get () {
        if (this.overrideFormulaKey !== undefined) {
          return this.element[this.overrideFormulaKey]
        }
        if (this.wizardImpactType === 0) {
          return this.element.formulaForOneTime || this.element.formulaForGivenYear
        } else {
          return this.element.formulaForGivenYear || this.element.formulaForOneTime
        }
      },
      set (formula) {
        if (this.overrideFormulaKey) {
          this.setSpecifiedFormula({
            key: this.overrideFormulaKey,
            formula
          })
        } else {
          const key = this.wizardImpactType === 0 ? 'formulaForOneTime' : 'formulaForGivenYear'
          this.setSpecifiedFormulaAndResetOthers({
            key,
            formula
          })
        }
      }
    },
    showSinkEditor () {
      if (this.useSinkEditor !== null) return this.useSinkEditor
      return this.existingFormula?.isSink
    },
    existingFormula () {
      if (this.element.formulaForOneTime) {
        return {
          type: 'Formula for one time',
          formula: this.element.formulaForOneTime
        }
      } else if (this.element.formulaForGivenYear) {
        return {
          type: 'Formula for given year',
          formula: this.element.formulaForGivenYear
        }
      } else if (this.element.sinkFormula || this.element.type === 'plant') {
        return {
          type: 'Sink formula',
          formula: this.element.sinkFormula,
          isSink: true
        }
      } else {
        return null
      }
    },
    wizardImpactType () {
      if (this.element.type === 'plant') return 1
      return this.overrideImpactType || this.internalImpactType || this.impactType
    }
  },
  watch: {
    ecosystem (val) {
      this.validate()
    },
    impactType (val) {
      this.internalImpactType = this.overrideImpactType === undefined ? val : this.overrideImpactType
      // this.co2PerUnit = 0
    },
    requestedFormula (formula) {
      if (formula) {
        this.co2Formula = formula
      }
    }
  },
  mounted () {
    this.fetchVariables()
    setTimeout(() => {
      if (this.existingFormula && this.existingFormula.isSink) {
        this.formulaType = 1
      }
      if (this.existingFormula) {
        this.isFormulaMode = true
      }
    }, 300)
  },
  methods: {
    ...mapMutations(['setFormulaMode', 'setSuggestedLayouts', 'setReplacements', 'setPercentOfCover', 'setShowReplacements']),
    ...mapActions(['setParsedElementFormula', 'predictAllLayoutFormulas', 'setSpecifiedFormulaAndResetOthers', 'setSpecifiedFormula']),
    ...mapGlobalActions(['showSnackbar']),

    async fetchVariables () {
      const { data: variables } = await this.$axios.get('/variables')
      // this.variables = variables.map(x => `$${x.name}`)
      this.variables = variables
    },
    setSinkFormula (formula, save) {
      this.showCo2FormulaDialog = false
      if (save) {
        this.formulaType = 1
        this.setParsedElementFormula({
          formula,
          co2PerUnit: this.co2PerUnit,
          formulaType: this.formulaType
        })
      }
    },
    handleFormulaInput (e) {
      this.co2Formula = e
      this.validate()
    },
    async evaluate (vars) {
      try {
        const scope = Object.fromEntries(vars.map(x => [x, 1]))
        if (this.co2Formula) {
          const res = await this.$axios.post('/utility/evaluate-formula', {
            formula: this.co2Formula,
            scope
          })
          this.formulaError = res.data.error
        }
      } catch (err) {
        switch (err.message) {
          default:
            this.formulaError = 'Invalid formula - ' + err.message
            break
        }
      }
    },
    async validate () {
      // evaluate formula to see if it's valid - don't actually evaluate variables yet, just pass all as 1
      const vars = ['$density', '$abc', '$qty',
        ...this.variables.map((x) => `$${x.name}`),
        ...this.project_variables,
        ...(this.element.type === 'hydrozone') ? this.eto_variables : [],
        ...(this.hasIrrigationTypeOptions && irrigationTypeHasField(this.element) ? this.irrigationTypeFields : []),
        ...((this.hasMaterialOptions || this.hasMaterialCategories) && materialHasField(this.element) ? this.material_variables : []),
        ...(this.hasNurseryOptions && nurseryHasField(this.element) ? this.nursery_variables : []),
        ...(this.hasPlantingTypeOptions && plantingTypeHasField(this.element) ? this.plantingTypeFields : []),
        ...(this.hasEcosystem && ecosystemHasField(this.element) ? this.ecosystem_variables : []),
        ...(this.hasDefaultTransportationOption && this.hasDefaultTransportationDistanceOption ? this.transportation_variables : [])
      ].map((value) => {
        if (value.prefix && value.name) {
          return `$${value.prefix}_${value.name}`
        }
        return value
      })

      if (this.wizardImpactType === 1) {
        vars.push('$yrs')
      }
      if (this.wizardImpactType === 0 && this.element.type === 'material') {
        if (this.showReplacements) {
          vars.push('$replacements')
          if (!this.co2Formula.includes('$replacements')) {
            this.formulaError = 'As this element is a material, the $replacements variable must be used in its formula.'
            return
          }
        }
      }
      if (this.element.type === 'plant') {
        vars.push('$percentOfCover')
      }
      await this.evaluate(vars)
    },
    irrigationTypeHasField,
    ecosystemHasField,
    nurseryHasField,
    materialHasField,
    plantingTypeHasField
  }
}
</script>

<style lang="scss" scoped>

</style>
