<template>
  <div>
    <draggable
      v-model="elementLayoutsDraggable"
      group="layouts"
      class="mb-3"
    >
      <v-card
        v-for="(layout, index) in elementLayoutsDraggable"
        :key="index"
        class="d-flex align-center mb-3 px-3 py-2"
        style="cursor: move; border-style: solid; border-width: 2px;"
        :style="{
          borderColor: getLayoutCardBorder(index)
        }"
      >
        <!-- <v-row v-if="activeLayout === index" class="px-3 align-center">
          <span class="shamrock--text caption px-2">
            Currently editing layout
          </span>
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn small icon @click="activeLayout = null" color="shamrock" v-on="on">
                <v-icon small>mdi-check</v-icon>
              </v-btn>
            </template>
            Finish editing
          </v-tooltip>
        </v-row> -->
        <v-row class="align-center px-3">
          <span
            v-for="(dimension, dimIndex) in layout.dimensions.filter(x => x.label)"
            :key="dimIndex"
            cols="auto"
          >
            <span v-if="dimension.label">
              <span>{{ dimension.label }}</span>
              <span
                class="caption pl-1"
                style="font-weight: 500;"
              >
                ({{ dimension.units[systemOfMeasurement].symbol }})
              </span>
            </span>
            <v-icon
              v-if="dimIndex < layout.dimensions.filter(x => x.label).length - 1"
              small
              class="mr-1"
              style="transform: translateY(-1px)"
            >
              mdi-close
            </v-icon>
          </span>
          <span
            v-if="!layout.dimensions.length || !layout.dimensions[0].label"
            class="caption px-2"
          >
            {{ activeLayout === index ? 'No dimensions yet, add some below.' : 'Click the edit icon to add dimensions.' }}
          </span>
          <v-spacer />
          <span
            v-if="activeLayout === index"
            class="caption shamrock--text pr-2"
          >
            Editing
          </span>
          <v-tooltip
            v-if="activeLayout !== index"
            top
          >
            <template v-slot:activator="{ on }">
              <v-btn
                small
                color="shamrock"
                icon
                @click="editLayout(index)"
                v-on="on"
              >
                <v-icon style="font-size: 20px;">
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            Edit dimensions
          </v-tooltip>
          <v-tooltip
            v-else
            top
          >
            <template v-slot:activator="{ on }">
              <v-btn
                small
                icon
                color="shamrock"
                @click="setActiveLayout(null)"
                v-on="on"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
            Finish editing
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  small
                  class="ml-1"
                  color="red"
                  icon
                  :disabled="layoutInUse(element.layouts[index])"
                  @click="deleteLayout(index)"
                >
                  <v-icon style="font-size: 20px;">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </div>
            </template>
            {{ layoutInUse(element.layouts[index]) ? 'Cannot delete dimension sets being used in projects' : 'Delete dimension set' }}
          </v-tooltip>
        </v-row>
      </v-card>
    </draggable>
    <v-btn
      class="mt-2"
      color="shamrock white--text"
      tile
      outlined
      small
      @click="createLayout()"
    >
      <v-icon left>
        mdi-plus
      </v-icon>
      New dimension set
    </v-btn>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import { createNamespacedHelpers, mapActions as mapGlobalActions } from 'vuex'
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('elements')

export default {
  name: 'LayoutList',
  components: {
    draggable
  },
  data () {
    return {
      highlightPreview: false
    }
  },
  computed: {
    ...mapGetters(['element', 'activeLayout', 'systemOfMeasurement', 'templateInUse']),
    elementLayoutsDraggable: {
      get () {
        return this.element.layouts
      },
      set (newLayouts) {
        if (this.templateInUse) {
          this.showSnackbar({ color: 'warning', text: 'Cannot reorder dimension sets as this element is being used in projects' })
        } else {
          this.setLayouts(newLayouts)
        }
      }
    }
  },
  methods: {
    ...mapMutations(['deleteLayout', 'setActiveLayout', 'setLayouts']),
    ...mapActions(['createLayout']),
    ...mapGlobalActions(['showSnackbar']),

    layoutInUse (layout) {
      const layoutExistsInDatabase = Boolean(layout._id)
      return this.templateInUse && layoutExistsInDatabase
    },
    getLayoutCardBorder (index) {
      const layout = this.element.layouts[index]
      if (layout.invalid) {
        return 'var(--v-error-base)'
      }
      if (!layout.dimensions.length || !layout.dimensions[0].label || !layout.formula) {
        return 'var(--v-warning-base)'
      }
      return this.activeLayout === index ? 'var(--v-shamrock-base)' : 'transparent'
    },
    editLayout (i) {
      this.setActiveLayout(i)
      this.highlightPreview = true
      setTimeout(() => {
        this.highlightPreview = false
      }, 2000)
    }
  }
}
</script>

<style>

</style>
