<template>
  <v-card
    :key="id"
    :loading="loading"
  >
    <v-card-title v-if="loading">
      Loading Template...
    </v-card-title>
    <v-card-title
      v-if="!loading"
      :class="{ 'px-1': $vuetify.breakpoint.xsOnly }"
    >
      <span class="headline pt-1">
        {{ !id ? 'New template' : `${element.name}` }}
      </span>
      <v-chip
        v-if="id"
        small
        class="mx-3 mt-1"
        outlined
      >
        editing
      </v-chip>
      <v-spacer />
      <!-- <span class="mr-2 subtitle-1">
        Units:
      </span>
      <v-select
        :value="systemOfMeasurement"
        :items="['metric', 'imperial']"
        style="max-width: 150px;"
        hide-details
        dense
        @change="e => $store.commit('setSystemOfMeasurement', e)"
      /> -->
    </v-card-title>
    <v-card-text
      v-if="!loading"
      :class="{ 'px-1': $vuetify.breakpoint.xsOnly }"
    >
      <ValidationObserver
        ref="observer"
        v-slot="{ handleSubmit, errors, invalid }"
      >
        <v-form @submit.prevent="handleSubmit(handleFormSubmit)">
          <v-row class="align-top">
            <v-col
              cols="12"
              sm="4"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="CPD Version"
                rules="required"
              >
                <v-select
                  v-model.number="cpdVersion"
                  :error-messages="errors"
                  data-vv-name="cpdVersion"
                  class="pt-1 pb-0"
                  label="CPD Version"
                  placeholder=""
                  :items="[2, 3]"
                />
              </ValidationProvider>
              <div class="title">
                Carbon Conscience IDs
              </div>
              <ValidationProvider
                v-slot="{ errors }"
                name="CC-ID"
              >
                <VueTagsInput
                  v-model="carbonConscienceIdTag"
                  class="pb-5"
                  :tags="carbonConscienceIdTags"
                  :error-messages="errors"
                  :avoid-adding-duplicates="true"
                  placeholder="Add CC-ID"
                  @tags-changed="carbonConscienceIdsChanged"
                />
              </ValidationProvider>
              <FileUpload
                :existing-file="element.epd && element.epd.includes('cpd-pathfinder') ? element.epd : null"
                :url="`${$baseURL}/api/templates/${id}/epd`"
                method="PUT"
                file-type="pdf"
                :empty-message="'No EPD uploaded'"
                :disabled-text="!id ? 'Please create the template first, then open in Edit to upload PDF' : undefined"
                width="400"
                height="200"
                @uploaded="e => element.epd = e"
              />
            </v-col>
            <v-col cols="24">
              <v-layout
                wrap
                class="stacked"
              >
                <v-card v-if="showIrrigationTypeDataInput">
                  <v-card-title>
                    Irrigation Types
                  </v-card-title>
                  <v-card-text>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Irrigation Types"
                    >
                      <v-autocomplete
                        v-model="irrigationTypes"
                        label="Types"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors"
                        :items="allIrrigationTypes"
                        small-chips
                        deletable-chips
                        multiple
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Irrigation Options"
                    >
                      <v-autocomplete
                        v-model="defaultIrrigationTypeOption"
                        label="Default"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors"
                        :items="allValidDefaultIrrigationTypeOptions"
                        small-chips
                        deletable-chips
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
                <v-card v-if="showStructuralDiversityDataInput">
                  <v-card-title>
                    {{ $t('admin.structuralDiversities.templateBuilder.sectionName') }}
                  </v-card-title>
                  <v-card-text>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Structural Diversity Options"
                    >
                      <v-autocomplete
                        v-model="structuralDiversities"
                        :label="$t('admin.structuralDiversities.templateBuilder.optionLabel')"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors"
                        :items="allStructuralDiversities"
                        small-chips
                        deletable-chips
                        multiple
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
                <v-card v-if="showPlantingTypeDataInput">
                  <v-card-title>
                    Planting Types
                  </v-card-title>
                  <v-card-text>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Planting Types"
                    >
                      <v-autocomplete
                        v-model="plantingTypes"
                        label="Types"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors"
                        :items="allPlantingTypes"
                        small-chips
                        deletable-chips
                        multiple
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Planting Type Options"
                    >
                      <v-autocomplete
                        v-model="defaultPlantingTypeOption"
                        label="Default"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors"
                        :items="allValidDefaultPlantingTypeOptions"
                        small-chips
                        deletable-chips
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
                <v-card v-if="showTransportationDataInput">
                  <v-card-title>
                    Transportation Options
                  </v-card-title>
                  <v-card-text>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Transportation Distance"
                    >
                      <v-select
                        v-model="defaultTransportationDistanceOption"
                        label="Default Distance"
                        class="pt-1 pb-0"
                        single-line
                        item-text="name"
                        :error-messages="errors"
                        item-value="_id"
                        :items="allTransportationDistanceOptions"
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-select>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Transportation Distance Options"
                    >
                      <v-select
                        v-model="defaultTransportationOption"
                        label="Default Transportation"
                        class="pt-1 pb-0"
                        single-line
                        item-text="name"
                        :error-messages="errors"
                        item-value="_id"
                        :items="allTransportationOptions"
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-select>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
                <v-card v-if="showMaterialDataInput">
                  <v-card-title>
                    Material Data
                  </v-card-title>
                  <v-card-text>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Material Categories"
                    >
                      <v-autocomplete
                        v-model="materialCategories"
                        class="pt-1 pb-0"
                        label="Categories"
                        item-text="name"
                        item-value="_id"
                        :items="allMaterialCategories"
                        :error-messages="errors"
                        small-chips
                        deletable-chips
                        multiple
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Material Categories Other"
                    >
                      <v-autocomplete
                        v-model="materials"
                        label="Other - In addition to categories"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :items="allMaterials"
                        :error-messages="errors"
                        small-chips
                        deletable-chips
                        multiple
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Default Material Categories"
                    >
                      <v-autocomplete
                        v-model="defaultMaterialOption"
                        label="Default Option"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors"
                        :items="allValidDefaultMaterialOptions"
                        small-chips
                        deletable-chips
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
                <v-card v-if="showNurseryDataInput">
                  <v-card-title>
                    Nursery Data
                  </v-card-title>
                  <v-card-text>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Nurseries"
                    >
                      <v-autocomplete
                        v-model="nurseries"
                        label="Nurseries"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors"
                        :items="allNurseries"
                        small-chips
                        deletable-chips
                        multiple
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Default Nurseries"
                    >
                      <v-autocomplete
                        v-model="defaultNurseryOption"
                        label="Default"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors"
                        :items="allValidDefaultNurseryOptions"
                        small-chips
                        deletable-chips
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
                <v-card v-if="showEcosystemDataInput">
                  <v-card-title>
                    Ecosystem Data
                  </v-card-title>
                  <v-card-text>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Ecosystems"
                    >
                      <v-autocomplete
                        v-model="ecosystem"
                        label="Ecosystems"
                        class="pt-1 pb-0"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors"
                        :items="allEcosystems"
                        small-chips
                        deletable-chips
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-card-text>
                </v-card>
                <v-card v-if="showPercentNativeDataInput">
                  <v-card-title>
                    Native Species
                  </v-card-title>
                  <v-card-text>
                    <v-row class="ma-0 pa-0">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Show Percent Native"
                      >
                        <v-checkbox
                          v-model="showPercentNative"
                          :error-messages="errors"
                          class="ma-0 pa-2"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Default Percent Native"
                      >
                        <v-text-field
                          v-model="defaultPercentNative"
                          :disabled="!showPercentNative"
                          class="mt-0 pa-0"
                          :error-messages="errors"
                          label="Percent Native"
                          placeholder=""
                          hide-details
                          type="number"
                          :step="0.5"
                        />
                      </ValidationProvider><br>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Show Target Condition"
                      >
                        <v-checkbox
                          v-model="showTargetCondition"
                          :error-messages="errors"
                          label="Show Target Condition"
                          class="ma-0 pa-2"
                        />
                      </ValidationProvider>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-layout>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col
              cols="12"
              sm="4"
            >
              <div class="title">
                Type
                <span class="error--text">*</span>
              </div>
              <div class="d-flex">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Type"
                  rules="required"
                >
                  <v-select
                    v-model="type"
                    :error-messages="errors"
                    data-vv-name="type"
                    class="pt-1 pb-0"
                    single-line
                    placeholder="Select a type for the template"
                    :items="['material', 'plant', 'maintenance', 'hydrozone']"
                  />
                </ValidationProvider>
                <v-dialog
                  v-model="showTemplateWizard"
                  width="650"
                >
                  <template #activator="{ on }">
                    <v-btn
                      :disabled="type !== 'material'"
                      color="shamrock"
                      outlined
                      class="ml-2"
                      elevation="0"
                      v-on="on"
                    >
                      Use material wizard
                      <v-icon right>
                        mdi-auto-fix
                      </v-icon>
                    </v-btn>
                  </template>
                  <MaterialTemplateWizard
                    v-model="showTemplateWizard"
                    @close="showTemplateWizard = false"
                    @save="handleTemplateWizardSave"
                  />
                </v-dialog>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <div class="title">
                Category
                <span class="error--text">*</span>
              </div>
              <ValidationProvider
                v-slot="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                  v-model="category"
                  :error-messages="errors"
                  data-vv-name="category"
                  class="pt-1 pb-0"
                  single-line
                  placeholder="Select a category for the template"
                  item-value="name"
                  item-text="name"
                  :disabled="!type"
                  :items="categories"
                />
              </ValidationProvider>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <div class="title">
                Sub category
                <span class="caption">Used for design suggestions</span>
                <span class="error--text ml-1">*</span>
              </div>
              <ValidationProvider
                v-slot="{ errors }"
                name="Sub-Category"
                :rules="{ required: !noSubcategories }"
              >
                <v-select
                  v-model="suggestionsCategory"
                  :error-messages="errors"
                  data-vv-name="sub-category"
                  class="pt-1 pb-0"
                  single-line
                  placeholder="Select a sub-category"
                  :disabled="!type || noSubcategories"
                  :items="categories && categories.find(x => x.name === category) ? categories.find(x => x.name === category).subCategories : []"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              class="pb-0 pt-0"
            >
              <div class="title">
                Name
                <span class="error--text">*</span>
              </div>
              <ValidationProvider
                v-slot="{ errors }"
                name="Name"
                rules="required|max:50"
              >
                <v-text-field
                  v-model="name"
                  :error-messages="errors"
                  data-vv-name="name"
                  :counter="50"
                  class="pt-1 pb-0"
                  single-line
                  placeholder="Element name"
                />
              </ValidationProvider>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="pb-0 pt-0"
            >
              <div class="title">
                Description
              </div>
              <ValidationProvider
                v-slot="{ errors }"
                name="Description"
                rules="max:300"
              >
                <v-textarea
                  v-model="description"
                  :error-messages="errors"
                  data-vv-name="description"
                  :counter="300"
                  class="pt-1 pb-0"
                  placeholder="Add a description here (optional)"
                  rows="1"
                />
              </ValidationProvider>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="pb-0 pt-0"
            >
              <div class="title">
                Related Elements
              </div>
              <ValidationProvider
                name="Related Elements"
              >
                <v-autocomplete
                  v-model="relatedElements"
                  class="pt-1 pb-0"
                  item-text="name"
                  item-value="_id"
                  :items="allValidElements"
                  small-chips
                  deletable-chips
                  multiple
                />
                <template v-slot:item="{ item }">
                  {{ item.name }}
                </template>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-if="type === 'hydrozone'">
            <v-col
              cols="12"
              lg="6"
            >
              <v-card>
                <v-card-title>Water Use Formula</v-card-title>
                <v-card-text>
                  <ImpactValues
                    override-formula-key="formulaForWaterUse"
                    :override-impact-type="1"
                    :allow-plant-formula="false"
                    :units="'liters'"
                    :requested-formula="waterStoredFormula"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <v-card>
                <v-card-title>CO₂e Impact Values</v-card-title>
                <v-card-text>
                  <ImpactValues
                    :override-impact-type="1"
                    :allow-plant-formula="false"
                    :requested-formula="wizardFormula"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="type !== 'hydrozone'">
            <v-col
              cols="12"
              md="2"
            >
              <div class="title">
                Impact type
              </div>
              <ValidationProvider
                name="Impact Type"
              >
                <v-radio-group
                  v-model="impactType"
                  :disabled="type === 'plant'"
                  class="mt-2"
                  color="shamrock"
                  :messages="type === 'plant' ? 'Plants must have an ongoing impact' : undefined"
                >
                  <v-radio
                    label="Once"
                    color="shamrock"
                    :value="0"
                    class="mb-4"
                  />
                  <v-radio
                    label="Ongoing"
                    color="shamrock"
                    :value="1"
                  />
                </v-radio-group>
              </ValidationProvider>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <div class="title d-flex align-center">
                CO₂e Unit
              </div>
              <v-tooltip top>
                <template #activator="{ on }">
                  <div v-on="on">
                    <ValidationProvider
                      name="CO2 measured per"
                    >
                      <v-select
                        ref="co2UnitSelect"
                        v-model="co2MeasuredPer"
                        class="mt-6"
                        label="CO2 measured per:"
                        placeholder=" "
                        :disabled="templateInUse"
                        :items="siUnitMappings.filter(x => x.system === systemOfMeasurement).map(x => x.symbol)"
                      />
                    </ValidationProvider>
                  </div>
                </template>
                <span v-if="templateInUse">Cannot change co2 unit for a template being used in projects</span>
                <span v-else>
                  Select the unit X for which you have a value: <strong>___kg CO2 per X</strong>
                </span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="impactType === 0 && type === 'material'"
              cols="12"
              md="4"
            >
              <div class="title">
                Replacements (materials only)
              </div>
              <v-tooltip top>
                <template #activator="{ on }">
                  <div v-on="on">
                    <div class="d-flex flex-row">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Show Replacements"
                      >
                        <v-checkbox
                          v-model="showReplacements"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        name="Replacements"
                      >
                        <v-text-field
                          v-model="replacements"
                          label="No. of replacements during lifespan"
                          placeholder=""
                          type="number"
                          :step="0.5"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                </template>
                Check box to show replacements option in calculator
              </v-tooltip>
            </v-col>
            <v-col
              v-if="type === 'plant'"
              cols="12"
              md="4"
            >
              <div class="title d-flex align-center">
                Percent of Cover (plants only)
              </div>
              <ValidationProvider
                name="Percent Of Cover"
              >
                <v-text-field
                  v-model="percentOfCover"
                  class="mt-6"
                  label="Percent of Cover"
                  placeholder=""
                  reverse
                  prefix="%"
                  type="number"
                  :step="0.5"
                />
              </ValidationProvider>
            </v-col>
            <v-col
              v-if="type === 'plant'"
              cols="12"
              md="4"
            >
              <div class="title d-flex align-center">
                {{ $t('admin.shadeArea.templateBuilder.sectionName') }}
              </div>
              <ValidationProvider
                :name="$t('admin.shadeArea.templateBuilder.label')"
              >
                <v-text-field
                  v-model="shadeArea"
                  class="mt-6"
                  :label="$t('admin.shadeArea.templateBuilder.label')"
                  placeholder=""
                  reverse
                  prefix="m2"
                  type="number"
                  :step="0.5"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-if="type !== 'hydrozone'">
            <v-col
              cols="12"
              lg="6"
            >
              <v-card>
                <v-card-title>CO2 Impact Values</v-card-title>
                <v-card-text>
                  <ImpactValues
                    :allow-plant-formula="true"
                    :requested-formula="wizardFormula"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="type !== 'maintenance'"
              cols="12"
              lg="6"
            >
              <v-card>
                <v-card-title>Carbon Stored</v-card-title>
                <v-card-text>
                  <ImpactValues
                    override-formula-key="formulaForCarbonStored"
                    :override-impact-type="1"
                    :allow-plant-formula="false"
                    :requested-formula="carbonStoredFormula"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="type !== 'maintenance'"
              cols="12"
              lg="6"
            >
              <v-card>
                <v-card-title>Nursery + Transportation Emissions</v-card-title>
                <v-card-text>
                  <ImpactValues
                    override-formula-key="formulaForOperational"
                    :override-impact-type="1"
                    :allow-plant-formula="false"
                    :requested-formula="operationalStoredFormula"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            class="mx-0 mb-1 pt-0 pb-2"
            style="border-radius: 3px;"
          >
            <v-col
              cols="12"
              sm="6"
              class="pl-0 pt-0"
            >
              <div class="mb-3 title">
                Dimensions
                <div
                  v-if="element.layouts.length > 1"
                  class="caption pt-1"
                >
                  Hint: Drag and drop dimension sets to reorder them
                </div>
              </div>
              <LayoutList />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="px-0"
            >
              <SuggestedLayouts />
            </v-col>
            <v-col
              v-if="activeLayout !== null"
              cols="12"
            >
              <v-card>
                <v-card-title class="title py-3">
                  Edit layout
                </v-card-title>
                <v-divider />
                <v-card-text class="pt-2">
                  <LayoutEditor />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="px-0 align-center">
            <v-col cols="auto">
              <ValidationProvider
                v-slot="{ errors }"
                name="Element density"
                :rules="showDensityField ? 'required' : ''"
              >
                <v-text-field
                  v-model="density"
                  data-vv-name="density"
                  :error-messages="errors"
                  type="number"
                  label="Element density"
                  style="max-width: 200px;"
                  suffix="kg/m3"
                  :disabled="!showDensityField"
                  @change="v => density = v"
                />
              </ValidationProvider>
            </v-col>
            <ValidationProvider
              v-slot="{ errors }"
              name="Area per unit"
              rules="max:100"
            >
              <v-text-field
                v-model="areaPerUnit"
                data-vv-name="areaPerUnit"
                :error-messages="errors"
                type="number"
                label="Area per unit"
                style="max-width: 200px;"
                suffix="m2/unit"
                :disabled="!showAreaPerUnitField"
              />
            </ValidationProvider>
          </v-row>
          <v-row>
            <FormErrors
              :vee-errors="errors"
              :other-errors="customErrors"
            />
          </v-row>
          <v-row class="px-3">
            <v-spacer />
            <v-btn
              text
              color="shamrock"
              class="mx-3"
              @click="$router.push('/admin/templates')"
            >
              Discard
            </v-btn>
            <v-btn
              color="shamrock white--text"
              :loading="submitting"
              :disabled="invalid"
              @click="handleFormSubmit"
            >
              {{ id ? 'Save template' : 'Create template' }}
            </v-btn>
          </v-row>
          <div
            class="mt-2 d-flex justify-end info--text"
            style="height: 30px;"
          >
            <span v-if="submitting">Please be patient while we update the element and refresh project stats where needed...</span>
          </div>
        </v-form>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>

<script>
import ImpactValues from './impactValues/ImpactValues'
import LayoutList from './LayoutList'
import LayoutEditor from './LayoutEditor'
import VueTagsInput from '@johmun/vue-tags-input'

import SuggestedLayouts from './SuggestedLayouts'
import MaterialTemplateWizard from './MaterialTemplateWizard'

import getUniqueRef from '@/helpers/getUniqueRef'
import ordinalize from '@/helpers/ordinalize'
import getLayoutSuggestions from '@/helpers/getLayoutSuggestions'
import FileUpload from '@/components/modals/FileUpload.vue'
import { createNamespacedHelpers, mapGetters as mapGlobalGetters, mapActions as mapGlobalActions } from 'vuex'

import { elementTypeHasField as irrigationTypeHasField } from '../../irrigationTypes/constants'
import { elementTypeHasField as structuralDiversityHasField } from '../../structuralDiversities/constants'
import { elementTypeHasField as materialHasField } from '../../materials/constants'
import { elementTypeHasField as plantingTypeHasField } from '../../plantingTypes/constants'
import { elementTypeHasField as ecosystemHasField } from '../../ecosystems/constants'
import { elementTypeHasField as nurseryHasField } from '../../nurseries/constants'
import { elementTypeHasField as percentNativeHasField } from '../../percentNative/constants'
import { elementTypeHasField as transportationTypeHasField } from '../../transportationOptions/constants'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import FormErrors from '../../../../components/form/FormErrors'

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('elements')

export default {
  name: 'TemplateForm',
  components: {
    ImpactValues,
    FormErrors,
    LayoutList,
    LayoutEditor,
    SuggestedLayouts,
    MaterialTemplateWizard,
    FileUpload,
    VueTagsInput,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    msg: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      templates: [],
      customErrors: [],
      suggestedLayouts: [],
      currPage: 0,
      highlightPreview: false,
      impactAlert: null,
      showCo2FormulaDialog: false,
      getUniqueRef,
      formulaType: 0,
      submitting: false,
      showTemplateWizard: false,
      wizardFormula: null,
      carbonStoredFormula: null,
      waterStoredFormula: null,
      operationalStoredFormula: null,
      allCategories: {},
      allElements: [],

      carbonConscienceIdTag: '',
      carbonConscienceIdTags: [],

      allMaterialCategories: [],
      allMaterials: [],

      allEcosystems: [],

      allNurseries: [],
      allPlantingTypes: [],
      allIrrigationTypes: [],
      allStructuralDiversities: [],
      allTransportationOptions: [],
      allTransportationDistanceOptions: [],

      allValidDefaultIrrigationTypeOptions: [],
      allValidDefaultStructuralDiversities: [],
      allValidDefaultMaterialOptions: [],
      allValidDefaultPlantingTypeOptions: [],
      allValidDefaultNurseryOptions: [],
      allValidElements: []
    }
  },
  computed: {
    ...mapGlobalGetters(['unitMappings']),
    ...mapGetters(['systemOfMeasurement', 'element', 'activeLayout', 'templateInUse']),

    noSubcategories () {
      const subCategories = this.categories?.find(x => x.name === this.category)?.subCategories || []
      return !this.type || subCategories.length === 0
    },
    siUnitMappings () { return this.unitMappings.filter((x) => x.toSi === 1) },
    categories () {
      if (this.type) {
        // hydrozones should use maintenance categories.
        // show version specific categories.
        return (this.allCategories[this.type === 'hydrozone' ? 'maintenance' : this.type] || [])
          .filter((category) => (category.cpdVersion || 2) === this.cpdVersion)
      } else {
        return []
      }
    },
    name: {
      get () {
        return this.element.name
      },
      set (val) {
        this.setName(val)
      }
    },
    description: {
      get () {
        return this.element.description
      },
      set (val) {
        this.setDescription(val)
      }
    },
    type: {
      get () {
        return this.element.type
      },
      set (val) {
        this.setType(val)
      }
    },
    carbonConscienceIds: {
      get () {
        return this.element.carbonConscienceIds
      },
      set (val) {
        this.setCarbonConscienceIds(val)
      }
    },
    category: {
      get () {
        return this.element.category
      },
      set (val) {
        if (val) {
          this.setCategory(val)
        }
      }
    },
    cpdVersion: {
      get () {
        return this.element.cpdVersion || 2
      },
      set (val) {
        if (val) {
          this.setCpdVersion(val)
        }
      }
    },
    suggestionsCategory: {
      get () {
        return this.element.suggestionsCategory
      },
      set (val) {
        if (val) {
          this.setSuggestionsCategory(val)
        }
      }
    },
    impactType: {
      get () {
        return this.$store.state.elements.impactType
      },
      set (val) {
        this.updateImpactType(val)
      }
    },
    replacements: {
      get () {
        return this.element.replacements
      },
      set (val) {
        this.setReplacements(val)
      }
    },
    showReplacements: {
      get () {
        return this.element.showReplacements
      },
      set (val) {
        this.setShowReplacements(val)
      }
    },
    percentOfCover: {
      get () {
        return this.element.percentOfCover
      },
      set (val) {
        this.setPercentOfCover(val)
      }
    },
    shadeArea: {
      get () {
        return this.element.shadeArea
      },
      set (val) {
        this.setShadeArea(val)
      }
    },
    density: {
      get () {
        return this.element.density
      },
      set (val) {
        this.setDensity(val)
      }
    },
    areaPerUnit: {
      get () {
        return this.element.areaPerUnit
      },
      set (val) {
        this.setAreaPerUnit(val)
      }
    },
    co2MeasuredPer: {
      get () {
        return this.element.co2MeasuredPer
      },
      set (symbol) {
        const { type } = this.unitMappings.find(x => x.symbol === symbol)

        // // if non SI symbol selected, do conversion first.
        // if (toSi !== 1) {
        //   const showAlert = this.co2PerUnit > 0
        //   const { symbol: newUnit } = unitMappings.find(x => x.type === type && x.toSi === 1)
        //   let newVal = this.co2PerUnit / toSi
        //   newVal = Math.round(newVal * 100000) / 100000
        //   const oldVal = this.co2PerUnit
        //   this.co2PerUnit = newVal
        //   if (showAlert) {
        //     this.impactAlert = `Converted entered value, ${oldVal} ${symbol} to ${newVal} ${newUnit}`
        //     setTimeout(() => {
        //       this.impactAlert = null
        //     }, 5000)
        //   }
        //   setTimeout(() => {
        //     const inputSlot = this.$refs.co2UnitSelect.$refs['input-slot']
        //     let elem = inputSlot.querySelector('.v-select__selection')
        //     if (elem) {
        //       elem.innerText = newUnit
        //     } else {
        //       const input = inputSlot.querySelector('.v-select__selections input')
        //       input.value = newUnit
        //     }
        //   }, 200)
        //   symbol = newUnit
        // }
        this.setCo2MeasuredPer(symbol)
        this.predictAllLayoutFormulas()
        this.setSuggestedLayouts(this.getSuggestedLayouts(type))
      }
    },
    relatedElements: {
      get () {
        return this.element.relatedElements
      },
      set (val) {
        this.setRelatedElements(val)
      }
    },
    ecosystem: {
      get () {
        return this.element.ecosystem
      },
      set (val) {
        this.setEcosystem(val)
      }
    },
    materialCategories: {
      get () {
        return this.element.materialCategories
      },
      set (val) {
        this.setMaterialCategories(val)
      }
    },
    structuralDiversities: {
      get () {
        return this.element.structuralDiversities
      },
      set (val) {
        this.setStructuralDiversities(val)
      }
    },
    defaultStructuralDiversities: {
      get () {
        return this.element.defaultStructuralDiversities
      },
      set (val) {
        this.setDefaultStructuralDiversities(val)
      }
    },
    irrigationTypes: {
      get () {
        return this.element.irrigationTypes
      },
      set (val) {
        this.setIrrigationTypes(val)
      }
    },
    defaultIrrigationTypeOption: {
      get () {
        return this.element.defaultIrrigationTypeOption
      },
      set (val) {
        this.setDefaultIrrigationTypeOption(val)
      }
    },
    materials: {
      get () {
        return this.element.materials
      },
      set (val) {
        this.setMaterials(val)
      }
    },

    defaultMaterialOption: {
      get () {
        return this.element.defaultMaterialOption
      },
      set (val) {
        this.setDefaultMaterialOption(val)
      }
    },
    nurseries: {
      get () {
        return this.element.nurseries
      },
      set (val) {
        this.setNurseries(val)
      }
    },
    defaultNurseryOption: {
      get () {
        return this.element.defaultNurseryOption
      },
      set (val) {
        this.setDefaultNurseryOption(val)
      }
    },
    defaultPercentNative: {
      get () {
        return this.element.defaultPercentNative
      },
      set (val) {
        this.setDefaultPercentNative(val)
      }
    },
    showPercentNative: {
      get () {
        return this.element.showPercentNative
      },
      set (val) {
        this.setShowPercentNative(val)
      }
    },
    showTargetCondition: {
      get () {
        return this.element.showTargetCondition
      },
      set (val) {
        this.setShowTargetCondition(val)
      }
    },
    plantingTypes: {
      get () {
        return this.element.plantingTypes
      },
      set (val) {
        this.setPlantingTypes(val)
      }
    },
    defaultPlantingTypeOption: {
      get () {
        return this.element.defaultPlantingTypeOption
      },
      set (val) {
        this.setDefaultPlantingTypeOption(val)
      }
    },

    defaultTransportationOption: {
      get () {
        return this.element.defaultTransportationOption
      },
      set (val) {
        this.setDefaultTransportationOption(val)
      }
    },

    defaultTransportationDistanceOption: {
      get () {
        return this.element.defaultTransportationDistanceOption
      },
      set (val) {
        this.setDefaultTransportationDistanceOption(val)
      }
    },
    showPlantingTypeDataInput () { return plantingTypeHasField(this) },
    showMaterialDataInput () { return materialHasField(this) },
    showIrrigationTypeDataInput () { return irrigationTypeHasField(this) },
    showStructuralDiversityDataInput () { return structuralDiversityHasField(this) },
    showEcosystemDataInput () { return ecosystemHasField(this) },
    showNurseryDataInput () { return nurseryHasField(this) },
    showPercentNativeDataInput () { return percentNativeHasField(this) },
    showTransportationDataInput () { return transportationTypeHasField(this) },
    showDensityField () {
      let show = false
      for (const layout of this.element.layouts) {
        if (layout.formula && layout.formula.includes('$density')) show = true
      }
      return show
    },
    showAreaPerUnitField () {
      let show = false
      for (const layout of this.element.layouts) {
        if (layout.formula && layout.formula.includes('$areaPerUnit')) show = true
      }
      return show
    }
  },
  watch: {
    type (val) {
      this.category = null
      this.suggestionsCategory = null
      if (val === 'material') {
        this.impactType = 0
      }
      if (val === 'plant') {
        this.impactType = 1
      }
    },
    cpdVersion (val) {
      this.category = null
      this.suggestionsCategory = null
      this.allValidElements = this.allElements.filter((element) => {
        return (element.cpdVersion || 2) === val
      })
    },
    irrigationTypes (val) {
      const validIrrigationTypes = this.allIrrigationTypes.filter((irrigationType) => val?.includes(irrigationType._id))
      this.allValidDefaultIrrigationTypeOptions = [...new Set([...validIrrigationTypes])]
    },
    structuralDiversities (val) {
      const validStructuralDiversities = this.allStructuralDiversities.filter((structuralDiversity) => val?.includes(structuralDiversity._id))
      this.allValidDefaultStructuralDiversities = [...new Set([...validStructuralDiversities])]
    },
    materials (val) {
      const validMaterials = this.allMaterials.filter((material) => val?.includes(material._id))
      const validMaterialsByCategory = this.allMaterials.filter((material) => this.materialCategories?.includes(material.materialCategory))
      this.allValidDefaultMaterialOptions = [...new Set([...validMaterials, ...validMaterialsByCategory])]
    },
    materialCategories (val) {
      const validMaterials = this.allMaterials.filter((material) => this.materials?.includes(material._id))
      const validMaterialsByCategory = this.allMaterials.filter((material) => val?.includes(material.materialCategory))
      this.allValidDefaultMaterialOptions = [...new Set([...validMaterials, ...validMaterialsByCategory])]
    },

    nurseries (val) {
      const validNurseries = this.allNurseries.filter((nursery) => val?.includes(nursery._id))
      this.allValidDefaultNurseryOptions = [...new Set([...validNurseries])]
    },
    plantingTypes (val) {
      const validPlantingType = this.allPlantingTypes.filter((plantingType) => val?.includes(plantingType._id))
      this.allValidDefaultPlantingTypeOptions = [...new Set([...validPlantingType])]
    },
    category (val) {
      // if only one subcategory within category, select it
      const category = this.categories.find(x => x.name === val)
      if (category && category.subCategories.length === 1) {
        this.suggestionsCategory = category.subCategories[0]
      }
    }
  },
  beforeDestroy () {
    this.resetElementState()
  },
  async created () {
    this.allIrrigationTypes = (await this.$axios.get('/irrigation_types')).data
    this.allMaterialCategories = (await this.$axios.get('/material_categories')).data
    this.allMaterials = (await this.$axios.get('/materials')).data
    this.allPlantingTypes = (await this.$axios.get('/planting_types')).data
    this.allEcosystems = (await this.$axios.get('/ecosystems')).data
    this.allNurseries = (await this.$axios.get('/nurseries')).data
    this.allStructuralDiversities = (await this.$axios.get('/structural_diversities')).data
    this.allTransportationOptions = (await this.$axios.get(`/transportation_options`)).data
    this.allTransportationDistanceOptions = (await this.$axios.get(`/transportation_distance_options`)).data
    this.allCategories = (await this.$axios.get('/element_categories/hierarchy')).data
    this.allElements = (await this.$axios.get(`/elements`)).data
    this.allValidElements = this.allElements.filter((element) => (element.cpdVersion || 2) === this.cpdVersion)

    this.resetElementState()
    if (this.id) {
      await this.loadExistingElement({ id: this.id })
      // set the ids to the tags accessor
      this.carbonConscienceIdTags = this.element.carbonConscienceIds.map((tag) => ({ text: tag }))
    } else {
      await this.resetElementState()
    }

    const dict = {
      custom: {
        emissions: { is_not: () => 'Emissions value cannot be zero.' },
        name: { max: () => 'Name may not be greater than 50 characters. Add detail in description instead.' },
        areaPerUnit: { required: () => 'Area per symbol is required.' }
      }
    }
    localize('en', dict)
    this.loading = false
  },
  methods: {
    ...mapMutations(['setName', 'setDensity', 'setCarbonConscienceIds', 'setCo2MeasuredPer', 'setCpdVersion',
      'setSuggestedLayouts', 'setAreaPerUnit', 'setCategory', 'setSuggestionsCategory', 'setType', 'resetElementState',
      'setDescription', 'setActiveLayout', 'setReplacements', 'setShowReplacements', 'setPercentOfCover', 'setShadeArea',
      'setStructuralDiversities', 'setDefaultStructuralDiversities',
      'setIrrigationTypes', 'setDefaultIrrigationTypeOption',
      'setEcosystem', 'setMaterialCategories', 'setMaterials', 'setDefaultMaterialOption',
      'setNurseries', 'setDefaultNurseryOption', 'setDefaultPercentNative', 'setShowPercentNative', 'setShowTargetCondition', 'setPlantingTypes', 'setDefaultPlantingTypeOption',
      'setDefaultTransportationOption', 'setDefaultTransportationDistanceOption',
      'setRelatedElements'
    ]),
    ...mapActions(['updateImpactType', 'loadExistingElement', 'predictAllLayoutFormulas']),
    ...mapGlobalActions(['showSnackbar']),
    async handleTemplateWizardSave ({ formula, units, replacements }) {
      this.replacements = replacements
      this.co2MeasuredPer = units.symbol
      this.wizardFormula = formula
      this.showTemplateWizard = false
    },
    carbonConscienceIdsChanged (newTag) {
      this.setCarbonConscienceIds(newTag.map((tag) => tag.text))
      this.carbonConscienceIdTags = newTag
    },
    getSuggestedLayouts (type) {
      let existingRefs = []
      return getLayoutSuggestions(type).map((x) => ({
        dimensions: x.map(y => {
          const ref = getUniqueRef(y.label, existingRefs)
          existingRefs.push(ref)
          return {
            ...y,
            ref
          }
        })
      }))
    },
    async handleFormSubmit () {
      this.submitting = true
      this.customErrors = []
      const validationResults = await this.$refs.observer.validate()

      await this.validateLayouts()
      const hasCustomErrors = (this.customErrors && this.customErrors.length !== 0) || !this.customErrors
      if (validationResults && !hasCustomErrors) {
        try {
          if (this.id) {
            await this.updateExistingTemplate()
          } else {
            await this.createNewTemplate()
          }
          this.$router.push('/admin/templates')
        } catch (err) {
          this.customErrors.push(err.response?.data?.message ?? err.message)
          throw err
        } finally {
          this.submitting = false
        }
      }
    },
    async updateExistingTemplate () {
      await this.$axios.put(`/templates/${this.id}`, { updates: this.element, templateInUse: this.templateInUse })
    },
    async createNewTemplate () {
      await this.$axios.post('/templates', this.element)
      this.showSnackbar(`Created new template successfully. "${this.element.name}" is now available for users to add to their projects.`)
      this.resetElementState()
    },
    validateLayouts () {
      if (!this.element.layouts || !this.element.layouts.length) {
        this.customErrors.push('Template must have at least one layout')
        return
      }
      for (const [i, layout] of this.element.layouts.entries()) {
        if (!layout.dimensions.length) {
          this.customErrors.push(`${ordinalize(i + 1)} layout in template is empty. Valid layouts must have at least one dimension.`)
          continue
        }
        if (!layout.formula) {
          this.customErrors.push(`${ordinalize(i + 1)} layout in template doesn't have a valid formula.`)
        }
      }
    },
    setMissingDimensions () {
      const layout = this.element.layouts[this.activeLayout]
      layout.dimensions = layout.dimensions.map(dim => ({
        ...dim,
        missingFromFormula: !layout.formula.includes(dim.ref)
      }))
    }
  }
}
</script>

<style lang="scss">
.stacked .v-card {
  display: inline !important;
  width: 45%;
  height:fit-content;
  margin: 8px !important;
}
.field {
  margin: 20px 0;
}
</style>
