var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('draggable',{staticClass:"mb-3",attrs:{"group":"layouts"},model:{value:(_vm.elementLayoutsDraggable),callback:function ($$v) {_vm.elementLayoutsDraggable=$$v},expression:"elementLayoutsDraggable"}},_vm._l((_vm.elementLayoutsDraggable),function(layout,index){return _c('v-card',{key:index,staticClass:"d-flex align-center mb-3 px-3 py-2",staticStyle:{"cursor":"move","border-style":"solid","border-width":"2px"},style:({
        borderColor: _vm.getLayoutCardBorder(index)
      })},[_c('v-row',{staticClass:"align-center px-3"},[_vm._l((layout.dimensions.filter(function (x) { return x.label; })),function(dimension,dimIndex){return _c('span',{key:dimIndex,attrs:{"cols":"auto"}},[(dimension.label)?_c('span',[_c('span',[_vm._v(_vm._s(dimension.label))]),_c('span',{staticClass:"caption pl-1",staticStyle:{"font-weight":"500"}},[_vm._v(" ("+_vm._s(dimension.units[_vm.systemOfMeasurement].symbol)+") ")])]):_vm._e(),(dimIndex < layout.dimensions.filter(function (x) { return x.label; }).length - 1)?_c('v-icon',{staticClass:"mr-1",staticStyle:{"transform":"translateY(-1px)"},attrs:{"small":""}},[_vm._v(" mdi-close ")]):_vm._e()],1)}),(!layout.dimensions.length || !layout.dimensions[0].label)?_c('span',{staticClass:"caption px-2"},[_vm._v(" "+_vm._s(_vm.activeLayout === index ? 'No dimensions yet, add some below.' : 'Click the edit icon to add dimensions.')+" ")]):_vm._e(),_c('v-spacer'),(_vm.activeLayout === index)?_c('span',{staticClass:"caption shamrock--text pr-2"},[_vm._v(" Editing ")]):_vm._e(),(_vm.activeLayout !== index)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"shamrock","icon":""},on:{"click":function($event){return _vm.editLayout(index)}}},on),[_c('v-icon',{staticStyle:{"font-size":"20px"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_vm._v(" Edit dimensions ")]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"shamrock"},on:{"click":function($event){return _vm.setActiveLayout(null)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)},[_vm._v(" Finish editing ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"red","icon":"","disabled":_vm.layoutInUse(_vm.element.layouts[index])},on:{"click":function($event){return _vm.deleteLayout(index)}}},[_c('v-icon',{staticStyle:{"font-size":"20px"}},[_vm._v(" mdi-delete ")])],1)],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.layoutInUse(_vm.element.layouts[index]) ? 'Cannot delete dimension sets being used in projects' : 'Delete dimension set')+" ")])],2)],1)}),1),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"shamrock white--text","tile":"","outlined":"","small":""},on:{"click":function($event){return _vm.createLayout()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New dimension set ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }