<template>
  <v-card
    elevation="1"
    class="sink-table pt-3 mb-5"
  >
    <v-card-title class="subtitle-2 pt-1 pb-3">
      {{ title }}
      <span class="font-weight-light caption">Hint: multiple cells can be pasted from excel at once</span>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-0">
      <v-data-table
        :headers="headers"
        :items="ages"
        :items-per-page="5"
        class="pt-1 px-4"
        hide-default-footer
        disable-pagination
        dense
      >
        <template v-slot:item.yearRange="{ value, item }">
          <span style="font-size: 16px;">
            <strong>{{ ages.find(x => x.yearRange === item.yearRange).yearRange.join(' - ') }}</strong>
            years
          </span>
        </template>
        <template v-slot:item.north="{ value, item, header }">
          <v-text-field
            ref="field"
            v-model="ages.find(x => x.yearRange === item.yearRange)[header.value]"
            type="number"
            style="max-width: 90px;"
            hide-details
            dense
            @paste="e => handlePaste(item, header.value, e)"
          />
        </template>
        <template v-slot:item.central="{ value, item, header }">
          <v-text-field
            v-model="ages.find(x => x.yearRange === item.yearRange)[header.value]"
            type="number"
            style="max-width: 90px;"
            hide-details
            dense
            @paste="e => handlePaste(item, header.value, e)"
          />
        </template>
        <template v-slot:item.south="{ value, item, header }">
          <v-text-field
            v-model="ages.find(x => x.yearRange === item.yearRange)[header.value]"
            type="number"
            style="max-width: 90px;"
            hide-details
            dense
            @paste="e => handlePaste(item, header.value, e)"
          />
        </template>
      </v-data-table>
      <LineChart
        v-if="degree"
        :chart-options="chartOptions"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import LineChart from '@/components/charts/LineChart.vue'
import getPolynomialPrediction from '@/helpers/getPolynomialPrediction'

const linChartData = async (ages, yearToPredictTo, columnName, min, max, zero, degree) => {
  const seriesDataX = []
  const seriesDataY = []
  let i
  let lastX
  let spacing = 1
  for (i = 0; i < ages.length; i += 1) {
    const yearRange = ages[i].yearRange
    spacing = (yearRange[1] - yearRange[0])
    lastX = yearRange[0] + spacing / 2
    seriesDataX.push(lastX)
    seriesDataY.push(Number(ages[i][columnName]))
  }
  const trainingX = [...seriesDataX.slice(3)]
  const trainingY = [...seriesDataY.slice(3)]

  const prediction = (await getPolynomialPrediction(
    { x: trainingX, y: trainingY, spacing, x2: yearToPredictTo, degree, zero: zero?.[columnName] }))

  const yIntercept = prediction.findIndex((value) => {
    return (value[1] < min) || (value[1] > max)
  })

  return seriesDataX.map((value, i) => {
    return { x: value, y: seriesDataY[i] }
  }).concat(prediction.map(([x, y]) => ({ x, y })).slice(0, yIntercept || prediction.length))
}

const linChartSeries = async (ages, yearToPredictTo, columnName, min, max, zero, degree) => {
  return {
    name: columnName,
    marker: {
      enabled: false
    },
    data: await linChartData(ages, yearToPredictTo, columnName, min, max, zero, degree)
  }
}

export default {
  name: 'SinkTable',
  components: {
    LineChart
  },
  props: {
    ages: {
      type: Array,
      default: () => [],
      required: true
    },
    min: {
      type: Number,
      default: null
    },
    yearToPredictTo: {
      type: Number,
      default: null
    },
    zero: {
      type: Object,
      default: () => {}
    },
    max: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    degree: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      chartOptions: {},
      headers: [
        { text: 'Age period', value: 'yearRange', align: 'right', sortable: false, width: 130 },
        { text: 'North', value: 'north', sortable: false },
        { text: 'Central', value: 'central', sortable: false },
        { text: 'South', value: 'south', sortable: false }
      ]
    }
  },
  watch: {
    ages: {
      immediate: true,
      deep: true,
      handler: async function () {
        if (this.degree) {
          this.chartOptions = await this.fetchChartOptions()
        }
      }
    },
    zero: {
      immediate: true,
      deep: true,
      handler: async function () {
        if (this.degree) {
          this.chartOptions = await this.fetchChartOptions()
        }
      }
    }
  },
  methods: {
    fetchChartOptions: async function () {
      if (this.yearToPredictTo && this.degree) {
        return {
          title: {
            text: `Best Fit (polynomial of degree: ${this.degree})`,
            style: {
              fontSize: '13px'
            }
          },
          series: [
            await linChartSeries(this.ages, this.yearToPredictTo, 'north', this.min, this.max, this.zero, this.degree),
            await linChartSeries(this.ages, this.yearToPredictTo, 'central', this.min, this.max, this.zero, this.degree),
            await linChartSeries(this.ages, this.yearToPredictTo, 'south', this.min, this.max, this.zero, this.degree)
          ]
        }
      }
      return {}
    },
    handlePaste (item, column, e) {
      e.preventDefault()
      const position = {
        x: this.headers.findIndex(x => x.value === column) - 1,
        y: this.ages.findIndex(x => x.yearRange[0] === item.yearRange[0])
      }
      const raw = e.clipboardData.getData('text')
      const rows = raw.split('\n')
      const data = rows.map(row => row.split('\t'))
      this.$emit('paste', { position, data })
    }
  }
}
</script>

<style lang="scss">
.sink-table {
  .v-data-table td:first-child {
    // padding: 0 !important;
  }
  td {
    border-bottom: none !important;
    padding-bottom: 1px;
  }
  td, th {
    border-right: 1px solid rgba(0,0,0,0.1) !important;
    &:last-child {
      border-right: none !important;
    }
  }
  // .v-card__text {
  //   max-height: 350px;
  //   overflow-y: auto;
  // }
}
</style>
