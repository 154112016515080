<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="dimensions"
      :items-per-page="5"
      class="layout-table pt-1 px-4"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.label="{ value, item: dimension }">
        <v-text-field
          placeholder="Add a label"
          hide-details
          dense
          :value="dimension.label"
          @change="label => updateDimensionLabel({ index: dimension.index, label })"
        />
      </template>
      <template v-slot:item.metricUnits="{ value, item: dimension }">
        <span v-if="dimension.type === 'custom'">
          n/a
        </span>
        <v-select
          v-else-if="systemOfMeasurement === 'metric'"
          :value="dimension.units.metric.symbol"
          hide-details
          dense
          :items="getAvailableUnitsForDimension(dimension, 'metric')"
          style="max-width: 100px;"
          @change="value => handleUnitSelect(dimension.index, value)"
        >
          <template v-slot:append-item>
            <v-divider class="mb-2" />
            <v-list-item
              disabled
              class="body-2"
            >
              As this layout is in use in projects, only other {{ dimension.type }} units may be selected
            </v-list-item>
          </template>
        </v-select>
        <span v-else>
          {{ dimension.units.metric.symbol }}
        </span>
      </template>
      <template v-slot:item.imperialUnits="{ value, item: dimension }">
        <span v-if="dimension.type === 'custom'">
          n/a
        </span>
        <v-select
          v-else-if="systemOfMeasurement === 'imperial'"
          :value="dimension.units[systemOfMeasurement].symbol"
          hide-details
          dense
          :items="getAvailableUnitsForDimension(dimension, 'imperial')"
          style="max-width: 100px;"
          @change="value => handleUnitSelect(dimension.index, value)"
        />
        <span v-else>
          {{ dimension.units.imperial.symbol }}
        </span>
      </template>
      <template v-slot:item.customUnits="{ value, item: dimension }">
        <v-text-field
          v-if="dimension.type === 'custom'"
          :value="dimension.units[systemOfMeasurement].symbol"
          class="mock-select"
          hide-details
          @change="value => handleUnitSelect(dimension.index, value)"
        >
          <template v-slot:append>
            <v-btn
              icon
              small
              style="transform: translateY(2px);"
            >
              <v-icon
                style="font-size: 20px"
                @click="resetUnits(dimension.index)"
              >
                mdi-restore
              </v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <span v-else>
          n/a
        </span>
      </template>
      <template v-slot:item.min="{ value, item: dimension }">
        <span v-if="dimension.type === 'area'">
          auto
        </span>
        <span v-else-if="!dimension.editable" />
        <v-text-field
          v-else
          :value="dimension.valueRange[0]"
          @change="value => updateDimensioninActiveLayout({ index: dimension.index, newEntries: { valueRange: [+value, dimension.valueRange[1]] } })"
        />
      </template>
      <template v-slot:item.max="{ value, item: dimension }">
        <span v-if="dimension.type === 'area'">
          auto
        </span>
        <span v-else-if="!dimension.editable" />
        <v-text-field
          v-else
          :value="dimension.valueRange[1]"
          @change="value => updateDimensioninActiveLayout({ index: dimension.index, newEntries: { valueRange: [dimension.valueRange[0], +value] } })"
        />
      </template>
      <template v-slot:item.editable="{ value, item: dimension }">
        <v-checkbox
          v-model="dimension.editable"
          dense
          @change="value => updateDimensioninActiveLayout({ index: dimension.index, newEntries: { editable: Boolean(value) } })"
        />
      </template>
      <template v-slot:item.formula="{ value, item: dimension }">
        <!-- <span v-if="dimension.editable">
          n/a
        </span> -->
        <div>
          <v-dialog
            v-model="showDimensionFormulaEditor"
            max-width="550px"
          >
            <template v-slot:activator="{ on }">
              <div class="d-flex">
                <FormulaPreview
                  v-if="dimension.fixedValueFormula"
                  :formula="dimension.fixedValueFormula"
                  :variables="projectVariables"
                  :co2-measured-per="dimension.units[systemOfMeasurement].symbol"
                />
                <v-btn
                  small
                  color="shamrock"
                  elevation="0"
                  outlined
                  :class="dimension.fixedValueFormula && 'ml-4'"
                  v-on="on"
                >
                  {{ dimension.fixedValueFormula ? 'Edit' : 'Add formula' }}
                </v-btn>
              </div>
            </template>
            <FormulaEditor
              :inputs="projectVariables"
              :output="`${dimension.units[systemOfMeasurement].symbol}`"
              :initial-formula="dimension.fixedValueFormula"
              @save="formula => handleSaveDimensionFormula(dimension.index, formula)"
            />
          </v-dialog>
        </div>
      </template>
      <template v-slot:item.delete="{ value, item: dimension }">
        <v-btn
          color="crimson"
          small
          outlined
          elevation="0"
          :disabled="layoutInUse"
          @click="deleteDimension(dimension.index)"
        >
          {{ layoutInUse ? 'Cannot delete dimensions from a dimension set being used in projects' : 'Delete' }}
          <v-icon
            right
            small
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="shamrock"
          text
          @click="createDimension()"
        >
          Add dimension
          <v-icon
            right
            style="font-size: 21px;"
          >
            mdi-plus-circle-outline
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:footer>
        <div
          v-if="dimensions.length > 0"
          class="d-flex justify-end my-2"
        >
          <v-btn
            color="shamrock"
            text
            :disabled="layoutInUse"
            @click="createDimension()"
          >
            {{ layoutInUse ? 'Cannot add dimensions to a dimension set being used in projects' : 'Add dimension' }}
            <v-icon
              right
              style="font-size: 21px;"
            >
              mdi-plus-circle-outline
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-row>
      <v-col class="px-10">
        <div style="max-width: 500px;">
          Formula for {{ element.co2MeasuredPer }}:
          <div class="d-flex">
            <FormulaPreview
              :formula="layoutFormula"
              :variables="projectVariables"
              :co2-measured-per="element.co2MeasuredPer"
            />
            <v-dialog
              v-model="showLayoutFormulaEditor"
              max-width="550px"
            >
              <template #activator="{ on }">
                <v-btn
                  text
                  color="shamrock"
                  class="mx-2"
                  v-on="on"
                >
                  <v-icon
                    left
                    small
                  >
                    mdi-pencil
                  </v-icon>
                  Edit Formula
                </v-btn>
              </template>
              <FormulaEditor
                :inputs="[...dimensionsToDisplayInFormula, ...projectVariables]"
                :output="`${element.co2MeasuredPer}`"
                :initial-formula="layoutFormula"
                @save="formula => handleSaveLayoutFormula(formula)"
              />
            </v-dialog>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormulaEditor from '@/components/modals/FormulaEditor'
import FormulaPreview from '@/components/atoms/FormulaPreview'

import { createNamespacedHelpers, mapGetters as mapGlobalGetters } from 'vuex'
import { ecosystemFields } from '../../ecosystems/constants'
import { nurseryFields } from '../../nurseries/constants'
const { mapGetters: mapElementGetters, mapMutations: mapElementMutations, mapActions: mapElementActions } = createNamespacedHelpers('elements')

export default {
  name: 'LayoutEditor',
  components: {
    FormulaPreview,
    FormulaEditor
  },
  data () {
    const ecosystem_variables = ecosystemFields
      .filter((field) => field.prefix).map((field) => ({ label: field.label, ref: `$${field.prefix}_${field.name}` }))
    const nursery_variables = nurseryFields
      .filter((field) => field.prefix).map((field) => ({ label: field.label, ref: `$${field.prefix}_${field.name}` }))

    return {
      headers: [
        { text: 'Dimension Label', value: 'label', align: 'left', sortable: false, width: 250 },
        { text: 'Metric units', value: 'metricUnits', sortable: false, width: 120 },
        { text: 'Imperial units', value: 'imperialUnits', sortable: false, width: 120 },
        { text: 'Custom units', value: 'customUnits', sortable: false, width: 120 },
        { text: 'Type', value: 'type', align: 'left', sortable: false, width: 110 },
        { text: 'Min value', value: 'min', sortable: false, width: 70 },
        { text: 'Max value', value: 'max', sortable: false, width: 70 },
        { text: 'User editable', value: 'editable', sortable: false, width: 110 },
        { text: 'Custom formula', value: 'formula', sortable: false },
        { text: '', value: 'delete', sortable: false, width: 50 }
      ],
      showDimensionFormulaEditor: false,
      showLayoutFormulaEditor: false,
      projectVariables: [
        { label: 'Material density', ref: '$t_distance' },
        { label: 'Material low carbon factor', ref: '$t_carbon_factor' },
        { label: 'Material density', ref: '$m_density' },
        { label: 'Material low carbon factor', ref: '$m_low_carbon_factor' },
        { label: 'Material median carbon factor', ref: '$m_median_carbon_factor' },
        { label: 'Material high carbon factor', ref: '$m_low_carbon_factor' },
        { label: 'Material carbon stored', ref: '$m_carbon_stored' },

        { label: 'Percent of Cover', ref: '$percentOfCover' },

        ...ecosystem_variables,
        ...nursery_variables,
        { label: 'Planted area of project', ref: '$planted_area' },
        { label: 'Total area of project', ref: '$total_area' },
        { label: 'Shrub area of project', ref: '$shrub_area' },
        { label: 'Lawn area of project', ref: '$lawn_area' }
      ]
    }
  },
  computed: {
    ...mapElementGetters(['element', 'activeLayout', 'systemOfMeasurement', 'templateInUse']),
    ...mapGlobalGetters(['dimensionUnitMappings', 'getEquivalentUnits']),

    layoutInUse () {
      const layoutExistsInDatabase = Boolean(this.element.layouts[this.activeLayout]._id)
      return this.templateInUse && layoutExistsInDatabase
    },
    otherSystemOfMeasurement () {
      return this.systemOfMeasurement === 'metric' ? 'imperial' : 'metric'
    },
    layoutFormula () {
      return this.element.layouts[this.activeLayout].formula
    },
    dimensionsToDisplayInFormula () {
      return this.dimensions.map(({ label, ...dimension }) => ({
        ...dimension,
        label: label + ` in ${dimension.units.metric.symbol}`
      }))
    },
    dimensions () {
      return this.element.layouts[this.activeLayout].dimensions.map((dimension, index) => ({
        ...dimension,
        editable: Boolean(dimension.editable),
        index
      }))
    }
  },
  methods: {
    ...mapElementMutations(['updateDimensioninActiveLayout', 'setFormulaForLayout']),
    ...mapElementActions(['updateDimensionLabel', 'updateDimensionUnit', 'createDimension', 'deleteDimension']),

    getAvailableUnitsForDimension (dimension, systemOfMeasurement) {
      const options = this.dimensionUnitMappings
        .filter(x => {
          if (this.layoutInUse && x.type !== dimension.type) {
            // cannot change dimension type
            return false
          }
          return x.system === systemOfMeasurement
        })
        .map(({ symbol }) => symbol)
      if (!this.layoutInUse) {
        options.push('custom')
      }
      return options
    },
    handleUnitSelect (index, val) {
      const units = this.dimensionUnitMappings.find(units => units.symbol === val)
      if (!units) {
        // if val isn't recognised as a unit, assume the units are custom
        if (val === 'custom') {
          this.updateDimensionUnit({
            index,
            units: {
              imperial: { symbol: 'units' },
              metric: { symbol: 'units' }
            },
            type: 'custom'
          })
        } else {
          this.updateDimensionUnit({
            index,
            units: {
              imperial: { symbol: val },
              metric: { symbol: val }
            },
            type: 'custom'
          })
        }
      } else {
        const system = units.system
        const otherSystem = this.otherSystemOfMeasurement
        const otherUnits = this.getEquivalentUnits(units.symbol)
        this.updateDimensionUnit({
          index,
          units: {
            [system]: { symbol: units.symbol, toSi: units.toSi },
            [otherSystem]: { symbol: otherUnits.symbol, toSi: otherUnits.toSi }
          },
          type: units.type
        })
      }
    },
    resetUnits (index) {
      this.handleUnitSelect(index, 'cm')
    },
    handleSaveDimensionFormula (index, formula) {
      this.showDimensionFormulaEditor = false
      this.updateDimensioninActiveLayout({
        index,
        newEntries: {
          fixedValueFormula: formula
        }
      })
    },
    handleSaveLayoutFormula (formula) {
      this.showLayoutFormulaEditor = false
      this.setFormulaForLayout({ formula, index: this.activeLayout, custom: true })
    }
  }
}
</script>

<style lang="scss">
// .layout-table {
//   td, th {
//     &:not(:last-child) {
//       border-right: 1px solid #ddd;
//     }
//   }
// }
</style>
