import { plantingTypeForm } from '@/views/admin/plantingTypes/constants'

export const structuralDiversityFields = []

export const structuralDiversityForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  {
    name: 'cpdStructuralDiversityId',
    label: 'CPD Structural Diversity Id'
  },
  {
    name: 'notes',
    label: 'Notes'

  },
  {
    name: 'citations',
    label: 'Citations'
  }
]

export const elementTypeHasField = (element) => {
  return element.cpdVersion === 3 && element.type === 'plant'
}
