var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"sink-table pt-3 mb-5",attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"subtitle-2 pt-1 pb-3"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('span',{staticClass:"font-weight-light caption"},[_vm._v("Hint: multiple cells can be pasted from excel at once")])]),_c('v-divider'),_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{staticClass:"pt-1 px-4",attrs:{"headers":_vm.headers,"items":_vm.ages,"items-per-page":5,"hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"item.yearRange",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"16px"}},[_c('strong',[_vm._v(_vm._s(_vm.ages.find(function (x) { return x.yearRange === item.yearRange; }).yearRange.join(' - ')))]),_vm._v(" years ")])]}},{key:"item.north",fn:function(ref){
var value = ref.value;
var item = ref.item;
var header = ref.header;
return [_c('v-text-field',{ref:"field",staticStyle:{"max-width":"90px"},attrs:{"type":"number","hide-details":"","dense":""},on:{"paste":function (e) { return _vm.handlePaste(item, header.value, e); }},model:{value:(_vm.ages.find(function (x) { return x.yearRange === item.yearRange; })[header.value]),callback:function ($$v) {_vm.$set(_vm.ages.find(function (x) { return x.yearRange === item.yearRange; }), header.value, $$v)},expression:"ages.find(x => x.yearRange === item.yearRange)[header.value]"}})]}},{key:"item.central",fn:function(ref){
var value = ref.value;
var item = ref.item;
var header = ref.header;
return [_c('v-text-field',{staticStyle:{"max-width":"90px"},attrs:{"type":"number","hide-details":"","dense":""},on:{"paste":function (e) { return _vm.handlePaste(item, header.value, e); }},model:{value:(_vm.ages.find(function (x) { return x.yearRange === item.yearRange; })[header.value]),callback:function ($$v) {_vm.$set(_vm.ages.find(function (x) { return x.yearRange === item.yearRange; }), header.value, $$v)},expression:"ages.find(x => x.yearRange === item.yearRange)[header.value]"}})]}},{key:"item.south",fn:function(ref){
var value = ref.value;
var item = ref.item;
var header = ref.header;
return [_c('v-text-field',{staticStyle:{"max-width":"90px"},attrs:{"type":"number","hide-details":"","dense":""},on:{"paste":function (e) { return _vm.handlePaste(item, header.value, e); }},model:{value:(_vm.ages.find(function (x) { return x.yearRange === item.yearRange; })[header.value]),callback:function ($$v) {_vm.$set(_vm.ages.find(function (x) { return x.yearRange === item.yearRange; }), header.value, $$v)},expression:"ages.find(x => x.yearRange === item.yearRange)[header.value]"}})]}}])}),(_vm.degree)?_c('LineChart',{attrs:{"chart-options":_vm.chartOptions}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }