var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"layout-table pt-1 px-4",attrs:{"headers":_vm.headers,"items":_vm.dimensions,"items-per-page":5,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var value = ref.value;
var dimension = ref.item;
return [_c('v-text-field',{attrs:{"placeholder":"Add a label","hide-details":"","dense":"","value":dimension.label},on:{"change":function (label) { return _vm.updateDimensionLabel({ index: dimension.index, label: label }); }}})]}},{key:"item.metricUnits",fn:function(ref){
var value = ref.value;
var dimension = ref.item;
return [(dimension.type === 'custom')?_c('span',[_vm._v(" n/a ")]):(_vm.systemOfMeasurement === 'metric')?_c('v-select',{staticStyle:{"max-width":"100px"},attrs:{"value":dimension.units.metric.symbol,"hide-details":"","dense":"","items":_vm.getAvailableUnitsForDimension(dimension, 'metric')},on:{"change":function (value) { return _vm.handleUnitSelect(dimension.index, value); }},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{staticClass:"body-2",attrs:{"disabled":""}},[_vm._v(" As this layout is in use in projects, only other "+_vm._s(dimension.type)+" units may be selected ")])]},proxy:true}],null,true)}):_c('span',[_vm._v(" "+_vm._s(dimension.units.metric.symbol)+" ")])]}},{key:"item.imperialUnits",fn:function(ref){
var value = ref.value;
var dimension = ref.item;
return [(dimension.type === 'custom')?_c('span',[_vm._v(" n/a ")]):(_vm.systemOfMeasurement === 'imperial')?_c('v-select',{staticStyle:{"max-width":"100px"},attrs:{"value":dimension.units[_vm.systemOfMeasurement].symbol,"hide-details":"","dense":"","items":_vm.getAvailableUnitsForDimension(dimension, 'imperial')},on:{"change":function (value) { return _vm.handleUnitSelect(dimension.index, value); }}}):_c('span',[_vm._v(" "+_vm._s(dimension.units.imperial.symbol)+" ")])]}},{key:"item.customUnits",fn:function(ref){
var value = ref.value;
var dimension = ref.item;
return [(dimension.type === 'custom')?_c('v-text-field',{staticClass:"mock-select",attrs:{"value":dimension.units[_vm.systemOfMeasurement].symbol,"hide-details":""},on:{"change":function (value) { return _vm.handleUnitSelect(dimension.index, value); }},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{staticStyle:{"transform":"translateY(2px)"},attrs:{"icon":"","small":""}},[_c('v-icon',{staticStyle:{"font-size":"20px"},on:{"click":function($event){return _vm.resetUnits(dimension.index)}}},[_vm._v(" mdi-restore ")])],1)]},proxy:true}],null,true)}):_c('span',[_vm._v(" n/a ")])]}},{key:"item.min",fn:function(ref){
var value = ref.value;
var dimension = ref.item;
return [(dimension.type === 'area')?_c('span',[_vm._v(" auto ")]):(!dimension.editable)?_c('span'):_c('v-text-field',{attrs:{"value":dimension.valueRange[0]},on:{"change":function (value) { return _vm.updateDimensioninActiveLayout({ index: dimension.index, newEntries: { valueRange: [+value, dimension.valueRange[1]] } }); }}})]}},{key:"item.max",fn:function(ref){
var value = ref.value;
var dimension = ref.item;
return [(dimension.type === 'area')?_c('span',[_vm._v(" auto ")]):(!dimension.editable)?_c('span'):_c('v-text-field',{attrs:{"value":dimension.valueRange[1]},on:{"change":function (value) { return _vm.updateDimensioninActiveLayout({ index: dimension.index, newEntries: { valueRange: [dimension.valueRange[0], +value] } }); }}})]}},{key:"item.editable",fn:function(ref){
var value = ref.value;
var dimension = ref.item;
return [_c('v-checkbox',{attrs:{"dense":""},on:{"change":function (value) { return _vm.updateDimensioninActiveLayout({ index: dimension.index, newEntries: { editable: Boolean(value) } }); }},model:{value:(dimension.editable),callback:function ($$v) {_vm.$set(dimension, "editable", $$v)},expression:"dimension.editable"}})]}},{key:"item.formula",fn:function(ref){
var value = ref.value;
var dimension = ref.item;
return [_c('div',[_c('v-dialog',{attrs:{"max-width":"550px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-flex"},[(dimension.fixedValueFormula)?_c('FormulaPreview',{attrs:{"formula":dimension.fixedValueFormula,"variables":_vm.projectVariables,"co2-measured-per":dimension.units[_vm.systemOfMeasurement].symbol}}):_vm._e(),_c('v-btn',_vm._g({class:dimension.fixedValueFormula && 'ml-4',attrs:{"small":"","color":"shamrock","elevation":"0","outlined":""}},on),[_vm._v(" "+_vm._s(dimension.fixedValueFormula ? 'Edit' : 'Add formula')+" ")])],1)]}}],null,true),model:{value:(_vm.showDimensionFormulaEditor),callback:function ($$v) {_vm.showDimensionFormulaEditor=$$v},expression:"showDimensionFormulaEditor"}},[_c('FormulaEditor',{attrs:{"inputs":_vm.projectVariables,"output":("" + (dimension.units[_vm.systemOfMeasurement].symbol)),"initial-formula":dimension.fixedValueFormula},on:{"save":function (formula) { return _vm.handleSaveDimensionFormula(dimension.index, formula); }}})],1)],1)]}},{key:"item.delete",fn:function(ref){
var value = ref.value;
var dimension = ref.item;
return [_c('v-btn',{attrs:{"color":"crimson","small":"","outlined":"","elevation":"0","disabled":_vm.layoutInUse},on:{"click":function($event){return _vm.deleteDimension(dimension.index)}}},[_vm._v(" "+_vm._s(_vm.layoutInUse ? 'Cannot delete dimensions from a dimension set being used in projects' : 'Delete')+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"shamrock","text":""},on:{"click":function($event){return _vm.createDimension()}}},[_vm._v(" Add dimension "),_c('v-icon',{staticStyle:{"font-size":"21px"},attrs:{"right":""}},[_vm._v(" mdi-plus-circle-outline ")])],1)]},proxy:true},{key:"footer",fn:function(){return [(_vm.dimensions.length > 0)?_c('div',{staticClass:"d-flex justify-end my-2"},[_c('v-btn',{attrs:{"color":"shamrock","text":"","disabled":_vm.layoutInUse},on:{"click":function($event){return _vm.createDimension()}}},[_vm._v(" "+_vm._s(_vm.layoutInUse ? 'Cannot add dimensions to a dimension set being used in projects' : 'Add dimension')+" "),_c('v-icon',{staticStyle:{"font-size":"21px"},attrs:{"right":""}},[_vm._v(" mdi-plus-circle-outline ")])],1)],1):_vm._e()]},proxy:true}])}),_c('v-row',[_c('v-col',{staticClass:"px-10"},[_c('div',{staticStyle:{"max-width":"500px"}},[_vm._v(" Formula for "+_vm._s(_vm.element.co2MeasuredPer)+": "),_c('div',{staticClass:"d-flex"},[_c('FormulaPreview',{attrs:{"formula":_vm.layoutFormula,"variables":_vm.projectVariables,"co2-measured-per":_vm.element.co2MeasuredPer}}),_c('v-dialog',{attrs:{"max-width":"550px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"text":"","color":"shamrock"}},on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Edit Formula ")],1)]}}]),model:{value:(_vm.showLayoutFormulaEditor),callback:function ($$v) {_vm.showLayoutFormulaEditor=$$v},expression:"showLayoutFormulaEditor"}},[_c('FormulaEditor',{attrs:{"inputs":_vm.dimensionsToDisplayInFormula.concat( _vm.projectVariables),"output":("" + (_vm.element.co2MeasuredPer)),"initial-formula":_vm.layoutFormula},on:{"save":function (formula) { return _vm.handleSaveLayoutFormula(formula); }}})],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }