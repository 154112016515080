<template>
  <v-card>
    <v-card-title>
      Easily generate a CO2 impact formula for your material template
    </v-card-title>
    <v-card-text>
      <v-form>
        <div class="mt-3">
          Enter emissions per unit from spreadsheet
        </div>
        <div class="d-flex align-center">
          <v-text-field
            v-model.number="co2PerUnit"
            type="number"
            style="max-width: 180px;"
            class="mr-3 mt-0"
            suffix="kgCO₂e"
          />
          <span class="mr-3">per</span>
          <v-select
            :value="units && units.symbol"
            :items="unitMappings"
            item-text="symbol"
            item-value="symbol"
            style="max-width: 180px;"
            class="mr-3 mt-0"
            @change="(e) => units = unitMappings.find((x) => x.symbol === e)"
          />
        </div>
        <div class="mt-3">
          On average, how many times will this material be replaced over the 50 year project lifespan?
        </div>
        <v-text-field
          v-model.number="replacements"
          style="max-width: 160px;"
          type="number"
          step="0.5"
          :suffix="replacements === 1 ? 'replacement' : 'replacements'"
        />
        <div
          v-if="formula"
          class="mt-3 mb-3"
        >
          Your generated formula:
        </div>
        <FormulaPreview
          v-if="formula"
          :formula="formula"
          co2-measured-per="kgCO₂e"
          :variables="[
            { ref: '$qty', label: `quantity (${siUnit.symbol})` },
            { ref: '$replacements', label: `replacements` },
          ]"
        />
        <v-alert
          v-if="formula && units.toSi !== 1"
          type="info"
          text
          color="success"
          class="mt-4 mb-0"
        >
          {{ (1 / units.toSi).toPrecision(3) }} represents the conversion from kg/{{ units.symbol }} to kg/{{ siUnit.symbol }}, which will be used for calculations in the backend.
        </v-alert>
      </v-form>
    </v-card-text>
    <v-card-actions class="pr-5 pb-5 justify-end">
      <v-btn
        color="error"
        outlined
        @click="onDiscard"
      >
        Discard
      </v-btn>
      <v-btn
        color="shamrock white--text"
        elevation="0"
        :disabled="!valid"
        @click="onSave"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormulaPreview from '@/components/atoms/FormulaPreview'
import { mapGetters as mapGlobalGetters } from 'vuex'

export default {
  name: 'MaterialTemplateWizard',
  components: {
    FormulaPreview
  },
  data () {
    return {
      co2PerUnit: 0,
      units: null,
      replacements: 0
    }
  },
  computed: {
    ...mapGlobalGetters(['unitMappings']),

    valid () {
      return this.co2PerUnit && this.units
    },
    siUnit () {
      return this.units && this.unitMappings.find((x) => x.type === this.units.type && x.toSi === 1)
    },
    formula () {
      if (!this.valid) {
        return null
      }
      let formula = `$qty * ${this.co2PerUnit}`
      if (this.units.toSi !== 1) {
        const siConversion = 1 / this.units.toSi
        formula += ` * ${Number(siConversion.toPrecision(8))}`
      }
      formula += ` * ($replacements + 1)`
      return formula
    }
  },
  methods: {
    resetForm () {
      this.co2PerUnit = 0
      this.units = null
      this.replacements = 0
    },
    onDiscard () {
      this.resetForm()
      this.$emit('close')
    },
    onSave () {
      if (this.valid) {
        this.$emit('save', {
          units: this.siUnit,
          formula: this.formula,
          replacements: this.replacements
        })
      }
    }
  }
}
</script>

<style>

</style>
