<template>
  <v-card class="elevation-2">
    <v-toolbar
      :dense="$vuetify.breakpoint.smAndUp"
      flat
      color="shamrock white--text"
    >
      <v-toolbar-title class="title">
        Suggested Dimensions
        <span
          class="caption"
          :class="{ 'd-block': $vuetify.breakpoint.xsOnly }"
        >(click to add to template)</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider />
    <v-list>
      <span
        v-if="!suggestedLayouts.length"
        class="caption px-4"
      >
        No dimension suggestions found
      </span>
      <v-list-item
        v-for="(layout, index) in suggestedLayouts"
        :key="index"
        style="min-height: 48px;"
        :disabled="layoutsUsed[index]"
        @click="addSuggestedLayoutToElement(layout)"
      >
        <v-list-item-content class="py-2">
          <v-list-item-title>
            <span
              v-for="(dimension, dimIndex) in layout.dimensions"
              :key="dimIndex"
              cols="auto"
              class="px-0"
            >
              <span class="body-2">{{ dimension.label }}</span>
              <!-- <span
                class="caption"
                style="font-weight: 500;"
              >
                ({{ dimension.units[systemOfMeasurement] }})
              </span> -->
              <v-icon
                v-if="dimIndex < layout.dimensions.length - 1"
                small
                class="mx-1"
              >
                mdi-close
              </v-icon>
            </span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-avatar class="my-1">
          <v-btn
            v-if="layoutsUsed[index]"
            small
            icon
            disabled
          >
            <v-icon right>
              mdi-check
            </v-icon>
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-card-actions
      v-if="suggestedLayouts.filter(x => !element.layouts.find(y => y.id === x.id)).length"
    >
      <v-spacer />
      <v-btn
        class="ma-1"
        color="shamrock white--text"
        tile
        text
        small
        @click="acceptAllSuggestions()"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Add all
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { createNamespacedHelpers, mapActions as mapGlobalActions } from 'vuex'
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('elements')

export default {
  name: 'SuggestedLayouts',

  computed: {
    ...mapGetters(['element', 'suggestedLayouts']),
    layoutsUsed () {
      const getDimensionTypes = layout => layout.dimensions.map(dimension => dimension.type).join(',')
      return this.suggestedLayouts.map((suggestedLayout, index) => (
        // check does layout with same dimension types exist in element already
        this.element.layouts.some(layout => getDimensionTypes(layout) === getDimensionTypes(suggestedLayout))
      ))
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapActions(['addSuggestedLayoutToElement']),
    ...mapGlobalActions([]),

    async acceptAllSuggestions () {
      for (const layout of [...this.suggestedLayouts]) {
        await this.addSuggestedLayoutToElement(layout)
      }
    }
  }
}
</script>

<style>

</style>
