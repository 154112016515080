<template>
  <v-card
    class="sink-editor"
    scrollable
  >
    <v-card-title class="headline">
      Plant formula editor
    </v-card-title>
    <v-card-text>
      <p>This editor should be used to input sequestration and decomposition data for maturing plants.</p>
      <v-card
        elevation="1"
        class="mt-4 mb-7 px-0"
      >
        <v-card-title class="subtitle-2">
          Mature sequestration rate (metric tons CO₂e per year)
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-simple-table
            class="sink-table"
            dense
          >
            <thead>
              <tr>
                <th />
                <th>North</th>
                <th>Central</th>
                <th>South</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="type in Object.keys(matureRates)"
                :key="type"
              >
                <td
                  class="text-right"
                  style="width: 200px;"
                >
                  <div
                    class="mt-1 text-capitalize font-weight-medium"
                  >
                    {{ type }}
                  </div>
                  <div class="caption mb-1">
                    (metric tons CO₂e per year)
                  </div>
                </td>
                <td
                  v-for="zone in Object.keys(matureRates[type])"
                  :key="zone"
                >
                  <v-text-field
                    v-model="matureRates[type][zone]"
                    hide-details
                    dense
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <v-card
        elevation="1"
        class="mt-4 mb-7 px-0"
      >
        <v-card-title class="subtitle-2">
          Max Life
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-simple-table
            class="sink-table"
            dense
          >
            <thead>
              <tr>
                <th />
                <th>North</th>
                <th>Central</th>
                <th>South</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="text-right"
                  style="width: 200px;"
                >
                  <div
                    class="mt-1 text-capitalize font-weight-medium"
                  >
                    Max Life
                  </div>
                </td>
                <td
                  v-for="zone in Object.keys(maximumLifeOfPlant)"
                  :key="zone"
                >
                  <v-text-field
                    v-model="maximumLifeOfPlant[zone]"
                    hide-details
                    dense
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <p>
        Age periods
      </p>
      <div class="px-1">
        <vue-slider
          v-model="boundaries"
          :drag-on-click="true"
          :enable-cross="false"
          :dot-options="dotOptions"
          :dot-size="18"
          :height="8"
          :mark="true"
          :interval="1"
          :adsorb="true"
          :min="0"
          :max="boundaries[boundaries.length - 1] + 5"
          :tooltip-formatter="v => `${v} years`"
          @dragging="handleDrag()"
          @drag-end="boundariesChanged()"
        >
          <template v-slot:dot>
            <div
              ref="slider-dot"
              class="vue-slider-dot-handle"
            />
          </template>
        </vue-slider>
        <div
          ref="axis"
          class="axis"
          :style="{margin: `0 -${margins}px`}"
        >
          <svg height="23px">
            <g :transform="`translate(${margins}, 0)`" />
          </svg>
        </div>
      </div>
      <v-row no-gutters>
        <v-spacer />
        <v-btn
          text
          color="shamrock"
          class="mb-3"
          @click="boundaries.push(boundaries[boundaries.length - 1] + 5)"
        >
          <v-icon left>
            mdi-plus-circle
          </v-icon>
          Add
        </v-btn>
        <v-btn
          text
          color="red"
          class="mb-3"
          @click="handleRemove"
        >
          <v-icon left>
            mdi-delete
          </v-icon>
          Remove
        </v-btn>
      </v-row>
      <SinkTable
        title="Tree Age/Survival Factors - Sequestration"
        :min="0"
        :max="1"
        :year-to-predict-to="100"
        :degree="3"
        :zero="maximumLifeOfPlant"
        :ages="survivalFactors.sequestration"
        @add-age-period="addAgePeriod('sequestration')"
        @update-age-period="updateAgePeriod('sequestration', $event)"
        @paste="pasteData('sequestration', $event)"
      />
      <SinkTable
        title="Tree Age/Survival Factors - Decomposition"
        :ages="survivalFactors.decomposition"
        :year-to-predict-to="100"
        :degree="1"
        @add-age-period="addAgePeriod('decomposition')"
        @update-age-period="updateAgePeriod('decomposition', $event)"
        @paste="pasteData('decomposition', $event)"
      />
      <SinkTable
        title="Tree Age/Replanting Factors"
        :ages="survivalFactors.replanting"
        @add-age-period="addAgePeriod('replanting')"
        @update-age-period="updateAgePeriod('replanting', $event)"
        @paste="pasteData('replanting', $event)"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="shamrock"
        style="transform: translateY(-10px);"
        @click="close()"
      >
        Close
      </v-btn>
      <v-btn
        class="mr-4"
        color="shamrock white--text"
        elevation="0"
        style="transform: translateY(-10px);"
        @click="saveAndClose()"
      >
        Save & Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SinkTable from './SinkTable'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import * as d3 from 'd3'
import throttle from 'lodash.throttle'

const margins = 5

export default {
  name: 'SinkEditor',
  components: {
    SinkTable,
    VueSlider
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    existingFormula: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      formula: '',
      error: null,
      boundaries: [0, 5, 10, 15, 20, 25, 30, 35, 40],
      maximumLifeOfPlant: {
        north: null,
        central: null,
        south: null
      },
      matureRates: {
        sequestration: {
          north: null,
          central: null,
          south: null
        },
        decomposition: {
          north: null,
          central: null,
          south: null
        }
      },
      survivalFactors: {
        sequestration: [],
        decomposition: [],
        replanting: []
      },
      margins
    }
  },
  computed: {
    dotOptions () {
      // return this.boundaries.map((x, i, arr) => i === 0 || i === arr.length - 1 ? { disabled: true } : null)
      return this.boundaries.map((x, i, arr) => i === 0 ? { disabled: true } : null)
    },
    parsedFormulaData () {
      const seqDecKeys = ['sequestration', 'decomposition']
      const growthZones = ['north', 'central', 'south'].map(key => ({
        name: key,
        matureRates: Object.fromEntries(seqDecKeys.map(type =>
          [type, +this.matureRates[type][key]]
        )),
        maximumLifeOfPlant: Number(this.maximumLifeOfPlant[key]),
        survivalFactors: Object.fromEntries([...seqDecKeys, 'replanting'].map(type =>
          [type, this.survivalFactors[type] && this.survivalFactors[type].map(period => (
            {
              yearRange: period.yearRange,
              value: +(period[key] || 0)
            }
          ))]
        ))
      }))
      return { growthZones }
    }
  },
  watch: {
    visible (val) {
      val && setTimeout(this.updateAxis, 1000)
    },
    boundaries (boundaries) {
      this.updateAxis()
      this.setRanges()
    }
  },
  created () {
    this.setRanges()
    if (this.existingFormula) {
      const sinkFormula = this.existingFormula.formula
      const seqDecKeys = ['sequestration', 'decomposition']
      const growthZones = ['north', 'central', 'south']

      this.maximumLifeOfPlant = Object.fromEntries(growthZones.map(zoneName => [
        zoneName,
        sinkFormula.growthZones.find(zone => zone.name === zoneName).maximumLifeOfPlant
      ]))
      this.matureRates = Object.fromEntries(seqDecKeys.map(key => [
        key,
        Object.fromEntries(growthZones.map(zoneName => [
          zoneName,
          sinkFormula.growthZones.find(zone => zone.name === zoneName).matureRates[key]
        ]))
      ]))
      this.survivalFactors = Object.fromEntries([...seqDecKeys, 'replanting'].map(key => [
        key,
        sinkFormula.growthZones[0].survivalFactors[key]?.map((period, index) => ({
          yearRange: period.yearRange,
          ...Object.fromEntries(sinkFormula.growthZones.map(zone => [
            zone.name,
            zone.survivalFactors[key][index].value
          ]))
        })) || []
      ]))
    }
  },
  mounted () {
    setTimeout(this.updateAxis, 1200)
  },
  methods: {
    addAgePeriod (type) {
      const ages = this.survivalFactors[type]
      const last = ages[ages.length - 1].yearRange[1]
      ages.push({
        yearRange: [last + 1, last + 2],
        north: 0,
        central: 0,
        south: 0
      })
    },
    updateAgePeriod (type, { index, val }) {
      const ages = this.survivalFactors[type]
      const oldVal = ages[index].yearRange[1]
      this.$set(this.survivalFactors[type][index].yearRange, 1, +val)
      // if last index
      if (index === this.survivalFactors[type].length - 1) {
        // const types = Object.keys(this.survivalFactors)
        // const arr = this.survivalFactors[types[types.indexOf(type) ? 0 : 1]]
        // arr[arr.length - 1].yearRange[1] = +val
      } else {
        this.$set(this.survivalFactors[type][index + 1].yearRange, 0, +val + 1)
        if (val > oldVal) {
          this.updateAgePeriod(index + 1, this.survivalFactors[type][index + 1].yearRange[1] + 1)
        }
      }
    },
    boundariesChanged () {
      this.boundaries = this.boundaries.map((x, i, arr) => {
        if (x > 100) {
          this.$store.dispatch('showSnackbar', 'Max age of 100 for plant formulas.')
          return 99
        }
        return arr[i - 1] === x ? x + 1 : x
      })
    },
    setRanges () {
      for (const key in this.survivalFactors) {
        this.survivalFactors[key] = this.boundaries.slice(0, this.boundaries.length - 1).map((x, i, arr) => {
          return ({
            ...this.survivalFactors[key][i],
            yearRange: [x, this.boundaries[i + 1]]
          })
        })
      }
    },
    pasteData (type, { position, data }) {
      const zoneMap = ['north', 'central', 'south']
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (this.survivalFactors[type][i + position.y]) {
            this.$set(this.survivalFactors[type][i + position.y], zoneMap[j + position.x], Number(data[i][j]))
          }
        }
      }
    },
    handleDrag: throttle(function () {
      this.updateAxis()
    }, 200),
    handleRemove () {
      if (this.boundaries.length > 2) {
        return this.boundaries.pop()
      }
      this.$store.dispatch('showSnackbar', "Must have at least two age periods. Please use annual CO₂e input instead if sequestration doesn't vary.")
    },
    updateAxis () {
      if (this.$refs.axis) {
        const rect = this.$refs.axis.getBoundingClientRect()
        const width = rect.right - rect.left
        const svg = d3.select(this.$refs.axis).select('svg').attr('width', width)
        const g = svg.select('g')
        const scale = d3.scaleLinear().domain([this.boundaries[0], d3.max(this.boundaries) + 5]).range([0, width - margins * 2])
        g.call(d3.axisBottom(scale).tickSize(10).ticks(Math.max(d3.max(this.boundaries) / 2), 10).tickFormat(d3.format('.0f')))
        g.selectAll('text').attr('dy', 10)
        g.selectAll('.tick').style('opacity', '1')
      }
    },
    close () {
      this.$emit('close', this.parsedFormulaData)
    },
    saveAndClose () {
      this.$emit('save', this.parsedFormulaData)
    }
  }
}
</script>

<style lang="scss">
  .vue-slider-process {
    background-color: var(--v-shamrock-base);
  }
  .vue-slider-dot-handle {
    background-color: var(--v-secondary-base);
  }
  .vue-slider-dot-tooltip-inner {
    background-color: var(--v-shamrock-base);
    &::after {
      border-top-color: var(--v-shamrock-base);
    }
  }
  .sink-editor .axis {
    position: relative;
    top: -9px;
    .domain {
      stroke: none;
    }
    .tick {
      opacity: 0;
    }
  }
</style>
